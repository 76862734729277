import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
    textAlign: 'center',
    '& b': {
      fontWeight: 'bold',
      color: 'red',
    },
  },
}))(Tooltip);

export const IconChipWithTooltip = ({
  tooltipTitle,
  tooltipHtml,
  chipLabel,
  chipClassName,
  icon,
  variant,
}) => (
  <HtmlTooltip
    title={
      !tooltipHtml ? (
        <React.Fragment>
          <span dangerouslySetInnerHTML={{ __html: tooltipHtml }} />
        </React.Fragment>
      ) : (
        tooltipTitle
      )
    }
  >
    <Chip
      label={chipLabel}
      style={chipClassName}
      icon={icon}
      variant={variant}
    />
  </HtmlTooltip>
);
