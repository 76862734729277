import { Grid, styled } from '@mui/material';

const PREFIX = 'MobileSearchGrid';

export const classes = {
  header: `${PREFIX}-header`,
  searchBar: `${PREFIX}-searchBar`,
  backButton: `${PREFIX}-backButton`,
  result: `${PREFIX}-result`,
  searchIcon: `${PREFIX}-searchIcon`,
  groupHeader: `${PREFIX}-groupHeader`,
};

export const MobileSearchGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.header}`]: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.default,
  },
  [`& .${classes.searchBar}`]: {
    padding: '10px',
    '@media (max-width: 600px)': {
      padding: '10px 10px 10px 0px',
    },
  },
  [`& .${classes.backButton}`]: {
    display: 'flex',
  },
  [`& .${classes.result}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
  },
  [`& .${classes.searchIcon}`]: {
    marginRight: '15px',
    color: 'grey',
  },
  [`& .${classes.groupHeader}`]: {
    fontSize: '0.875rem',
    fontWeight: 500,
    padding: '10px',
  },
}));
