import React from 'react';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import useStyles from '../style';
import ReactGA from 'react-ga4';
import { ROUTE } from 'routes';
import { PAGES } from 'pages';

const TermsOfUseText = () => {
  ReactGA.send({ hitType: 'pageview', page: ROUTE.TERMS, title: PAGES.TERMS });
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.note} variant="h7">
        Last updated: February 07, 2023
      </Typography>
      <Typography className={classes.note} variant="h7">
        Please read these terms and conditions carefully before using Our
        Service.
      </Typography>
      <Typography className={classes.subtitle} variant="h6">
        Interpretations and Definitions
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        Interpretation
      </Typography>
      <Typography className={classes.note} variant="h7">
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        Definitions
      </Typography>
      <Typography className={classes.note} variant="h7">
        For the purposes of these Terms and Conditions:
        <ul>
          <li className={classes.bulletPoint}>
            <strong>Country</strong> refers to: Alberta, Canada
          </li>
          <li className={classes.bulletPoint}>
            <strong>Company</strong> (referred to as either &quot;the
            Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
            this Agreement) refers to Cybera Inc., 400 10055 - 106 Street NW
            Edmonton, AB T5J 2Y2.
          </li>
          <li className={classes.bulletPoint}>
            <strong>Device</strong> means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
          </li>
          <li className={classes.bulletPoint}>
            <strong>Service</strong> refers to the Website.
          </li>
          <li className={classes.bulletPoint}>
            <strong>Terms and Conditions</strong> (also referred as
            &quot;Terms&quot;) mean these Terms and Conditions that form the
            entire agreement between You and the Company regarding the use of
            the Service.
          </li>
          <li className={classes.bulletPoint}>
            <strong>Website</strong> refers to SkillUp, accessible from{' '}
            <a
              className={classes.link}
              href="https://skill-up.ca"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              skill-up.ca
            </a>
          </li>
          <li>
            <strong>You</strong> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </li>
        </ul>
      </Typography>
      <Typography className={classes.subtitle} variant="h6">
        Acknowledgment
      </Typography>
      <Typography className={classes.note} variant="h7">
        These are the Terms and Conditions governing the use of this Service and
        the agreement that operates between You and the Company. These Terms and
        Conditions set out the rights and obligations of all users regarding the
        use of the Service.
      </Typography>
      <Typography className={classes.note} variant="h7">
        Your access to and use of the Service is conditioned on Your acceptance
        of and compliance with these Terms and Conditions. These Terms and
        Conditions apply to all visitors, users and others who access or use the
        Service.
      </Typography>
      <Typography className={classes.note} variant="h7">
        By accessing or using the Service You agree to be bound by these Terms
        and Conditions. If You disagree with any part of these Terms and
        Conditions then You may not access the Service.
      </Typography>
      <Typography className={classes.note} variant="h7">
        You represent that you are over the age of 18. The Company does not
        permit those under 18 to use the Service.
      </Typography>
      <Typography className={classes.note} variant="h7">
        Your access to and use of the Service is also conditioned on Your
        acceptance of and compliance with the Privacy Policy of the Company. Our
        Privacy Policy describes Our policies and procedures on the collection,
        use and disclosure of Your personal information when You use the
        Application or the Website and tells You about Your privacy rights and
        how the law protects You. Please read Our Privacy Policy carefully
        before using Our Service.
      </Typography>
      <Typography className={classes.subtitle} variant="h6">
        Links to Other Websites
      </Typography>
      <Typography className={classes.note} variant="h7">
        Our Service may contain links to third-party web sites or services that
        are not owned or controlled by the Company.
      </Typography>
      <Typography className={classes.note} variant="h7">
        The Company has no control over, and assumes no responsibility for, the
        content, privacy policies, or practices of any third party web sites or
        services. You further acknowledge and agree that the Company shall not
        be responsible or liable, directly or indirectly, for any damage or loss
        caused or alleged to be caused by or in connection with the use of or
        reliance on any such content, goods or services available on or through
        any such web sites or services.
      </Typography>
      <Typography className={classes.note} variant="h7">
        We strongly advise You to read the terms and conditions and privacy
        policies of any third-party web sites or services that You visit.
      </Typography>
      <Typography className={classes.subtitle} variant="h6">
        Limitation of Liability
      </Typography>
      <Typography className={classes.note} variant="h7">
        Notwithstanding any damages that You might incur, the entire liability
        of the Company and any of its suppliers under any provision of these
        Terms and Your exclusive remedy for all of the foregoing shall be
        limited to the amount actually paid by You for the Service. In no event
        shall the Company or its suppliers be liable for any special,
        incidental, indirect, or consequential damages whatsoever.
      </Typography>
      <Typography className={classes.subtitle} variant="h6">
        Governing Law
      </Typography>
      <Typography className={classes.note} variant="h7">
        The laws of the Country, excluding its conflicts of law rules, shall
        govern these Terms and Your use of the Service. Your use of the
        Application may also be subject to other local, state, national, or
        international laws.
      </Typography>
      <Typography className={classes.subtitle} variant="h6">
        Disputes Resolution
      </Typography>
      <Typography className={classes.note} variant="h7">
        If You have any concern or dispute about the Service, You agree to first
        try to resolve the dispute informally by contacting the Company.
      </Typography>
      <Typography className={classes.subtitle} variant="h6">
        Severability and Waiver
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        Severability
      </Typography>
      <Typography className={classes.note} variant="h7">
        If any provision of these Terms is held to be unenforceable or invalid,
        such provision will be changed and interpreted to accomplish the
        objectives of such provision to the greatest extent possible under
        applicable law and the remaining provisions will continue in full force
        and effect.
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        Waiver
      </Typography>
      <Typography className={classes.note} variant="h7">
        Except as provided herein, the failure to exercise a right or to require
        performance of an obligation under these Terms shall not effect a
        party's ability to exercise such right or require such performance at
        any time thereafter nor shall the waiver of a breach constitute a waiver
        of any subsequent breach.
      </Typography>
      <Typography className={classes.subtitle} variant="h6">
        Changes to These Terms and Conditions
      </Typography>
      <Typography className={classes.note} variant="h7">
        We reserve the right, at Our sole discretion, to modify or replace these
        Terms at any time.
      </Typography>
      <Typography className={classes.note} variant="h7">
        By continuing to access or use Our Service after those revisions become
        effective, You agree to be bound by the revised terms. If You do not
        agree to the new terms, in whole or in part, please stop using the
        website and the Service.
      </Typography>
      <Typography className={classes.subtitle} variant="h6">
        Contact Us
      </Typography>
      <Typography className={classes.note} variant="h7">
        For the purposes of these Terms and Conditions:
        <ul>
          <li>By email: privacy@cybera.ca</li>
        </ul>
      </Typography>
    </>
  );
};

export const TermsOfUse = ({ modal }) => {
  const classes = useStyles();

  return modal ? (
    <TermsOfUseText />
  ) : (
    <Container className={classes.page}>
      <Typography className={classes.title} variant="h4">
        Terms Of Use
      </Typography>
      <TermsOfUseText />
    </Container>
  );
};

export default TermsOfUse;
