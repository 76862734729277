import React, { useState, useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { PHOTON_GRAPH_URL } from 'environmentConfig';
import { classes, MobileSearchGrid } from './style';

const ALL_JOBTITLES = gql`
  query AllOccupations($jobTitleName: String!) {
    occupationJobTitles(
      filter: { jobTitle: { name: { includesInsensitive: $jobTitleName } } }
      orderBy: FREQUENCY_DESC
      first: 15
    ) {
      nodes {
        jobTitle {
          id
          name
        }
        occupation {
          id
          name
          careerArea {
            id
            name
          }
        }
      }
    }
    occupations(filter: { name: { includesInsensitive: $jobTitleName } }) {
      nodes {
        id
        name
        careerArea {
          id
          name
        }
      }
    }
  }
`;

const TOP_JOBTITLES = gql`
  query TopJobTitles {
    occupationJobTitles(orderBy: FREQUENCY_DESC, first: 10) {
      nodes {
        jobTitle {
          id
          name
        }
        occupation {
          id
          name
          careerArea {
            id
            name
          }
        }
      }
    }
  }
`;

const JobTitleSearchMobile = ({
  toggleSearchDrawer,
  onChange,
  occupation,
  jobTitle,
  app,
}) => {
  const [inputValue, setInputValue] = useState(
    jobTitle.length > 0 ? jobTitle : occupation
  );

  useEffect(() => {
    setInputValue(occupation);
  }, [occupation]);

  const [getJobTitles] = useLazyQuery(ALL_JOBTITLES, {
    context: {
      uri: PHOTON_GRAPH_URL,
    },
  });

  const [topJobTitles] = useLazyQuery(TOP_JOBTITLES, {
    context: {
      uri: PHOTON_GRAPH_URL,
    },
  });

  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (inputValue.length > 0) {
      setLoading(true);
      getJobTitles({
        variables: {
          jobTitleName: inputValue.length > 0 ? inputValue : occupation,
        },
        onCompleted: data => {
          let list = [];
          setJobTitleOptions([]);
          inputValue.length > 0 &&
            data?.occupationJobTitles.nodes.forEach(item =>
              list.push({
                jobTitle: item.jobTitle.name,
                label: item.occupation.name,
                careerArea: item.occupation.careerArea.name,
              })
            );
          data?.occupations.nodes.forEach(item =>
            list.push({
              jobTitle: '',
              label: item.name,
              careerArea: item.careerArea.name,
            })
          );
          setJobTitleOptions(list);
          setLoading(false);
        },
      });
    } else {
      setLoading(true);
      topJobTitles({
        onCompleted: data => {
          let list = [];
          data?.occupationJobTitles.nodes.forEach(item =>
            list.push({
              jobTitle: item.jobTitle.name,
              label: item.occupation.name,
              careerArea: item.occupation.careerArea.name,
            })
          );
          setJobTitleOptions(list);
          setLoading(false);
        },
      });
    }
  }, [inputValue]);

  const formatResult = jobTitle => {
    if (inputValue.length > 0) {
      const matches = match(jobTitle, inputValue, {
        insideWords: true,
        requireMatchAll: true,
      });

      const parts = parse(jobTitle, matches);

      return parts.map((part, index) => (
        <span
          key={index}
          style={{
            fontWeight: part.highlight ? 700 : 400,
            whiteSpace: 'pre',
          }}
        >
          {part.text}
        </span>
      ));
    }

    return jobTitle;
  };

  let keyCounter = 0;

  const generateUniqueKey = () => {
    keyCounter += 1;
    return keyCounter;
  };

  return (
    <MobileSearchGrid container>
      <Grid item xs={12} className={classes.header}>
        <Grid container className={classes.searchBar}>
          <Grid item xs={2} sm={1} className={classes.backButton}>
            <IconButton
              color={app}
              aria-label="Back"
              onClick={() => toggleSearchDrawer(false)}
              sx={{ width: '100%' }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10} sm={11}>
            <Autocomplete
              id="jobField"
              options={jobTitleOptions}
              filterOptions={x => x}
              loading={loading}
              freeSolo
              forcePopupIcon={false}
              open
              sx={{
                '& .MuiOutlinedInput-root': {
                  padding: '10px',
                },
                '& .MuiAutocomplete-popupIndicator': {
                  transform: 'none',
                },
                width: '100%',
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                // Only set input value when user types
                if (event !== null && event.type !== 'blur') {
                  setInputValue(newInputValue);
                }
              }}
              //eslint-disable-next-line
              PaperComponent={({ children, ...other }) => {
                // Ensure no duplicate keys in the case where job title is equal to an occupation name
                return (
                  <Paper
                    sx={{
                      display: 'none',
                    }}
                    key={generateUniqueKey()}
                    {...other}
                  />
                );
              }}
              getOptionDisabled={option => option.jobTitle === 'Job Title'}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Start typing your job title"
                  inputRef={input => input && input.focus()}
                  variant="outlined"
                  color={app}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      borderRadius: '12px',
                    },
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {jobTitleOptions.map((value, index) => (
          <Box
            key={value.label + '-' + value.jobTitle + '-' + index}
            className={classes.result}
            onClick={() => {
              onChange({
                area: value.careerArea,
                occupation: value.label,
                jobTitle: value.jobTitle,
              });
              toggleSearchDrawer(false);
            }}
          >
            {inputValue.length > 0 ? (
              <SearchIcon className={classes.searchIcon} />
            ) : (
              <TrendingUpIcon className={classes.searchIcon} />
            )}
            <Box>
              {formatResult(
                value.jobTitle.length > 0 ? value.jobTitle : value.label
              )}
              {value.jobTitle.length > 0 && (
                <Box
                  style={{
                    fontStyle: 'italic',
                    color: 'grey',
                    fontSize: '14px',
                  }}
                >
                  <span>&nbsp;in&nbsp;</span>
                  <span>{value.label}</span>
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </Grid>
    </MobileSearchGrid>
  );
};

export default JobTitleSearchMobile;
