import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import useMediaQuery from '@mui/material/useMediaQuery';

import { classes, Form } from '../style.js';
import { Typography } from '@mui/material';

import { gql, useLazyQuery } from '@apollo/client';

import featureFlagManager, { FLAG } from 'modules/featureFlagManager';
import PasswordInput from '../components/PasswordInput';
import useEmailValidation from 'hooks/useEmailValidation.js';
import { signInWithGooglePopup } from '../../../firebaseApp';
import ReactGA from 'react-ga4';
import { ROUTE } from 'routes';
import { PAGES } from 'pages';

const LOGIN_USER = gql`
  query LOGIN_USER($email: String!, $password: String!) {
    userAccount(input: { email: $email, password: $password })
      @rest(
        type: "User"
        path: "/users/login"
        method: "POST"
        endpoint: "v1"
      ) {
      id
      email
      firstname
      lastname
      photo_url
      expired
      approved
      token
      roles
    }
  }
`;

const LOGIN_FIREBASE_USER = gql`
  query LOGIN_FIREBASE_USER($token: String!) {
    userAccount(input: { token: $token })
      @rest(
        type: "User"
        path: "/users/login-firebase"
        method: "POST"
        endpoint: "v1"
      ) {
      id
      email
      firstname
      lastname
      photo_url
      expired
      approved
      token
      roles
    }
  }
`;

export const LoginForm = ({
  setForm,
  setMsg,
  showErrorMsg,
  redirect = null,
  setRedirect,
  hideLogin,
  navigateAdminSettings = false,
}) => {
  ReactGA.send({ hitType: 'pageview', page: ROUTE.LOGIN, title: PAGES.LOGIN });
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const { email, setEmail, validateEmail } = useEmailValidation('', null);

  const [loginUser] = useLazyQuery(LOGIN_USER, {
    fetchPolicy: 'network-only',
  });

  const [loginFirebaseUser] = useLazyQuery(LOGIN_FIREBASE_USER, {
    fetchPolicy: 'network-only',
  });

  const isSmallScreen = useMediaQuery(
    `(max-width:${localStorage.getItem('MD_SCREEN')})`
  );

  const handleForm = type => {
    if (
      isSmallScreen &&
      featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY)
    ) {
      navigate('/' + type);
    }
    setForm(type);
    setPassword('');
    setEmail('');
    setMsg('');
    showErrorMsg(false);
  };

  useEffect(() => {
    if (redirect) {
      handleGoogleLogin();
    }
  }, []);

  useEffect(() => {
    validateEmail();
  }, [email]);

  const login = user => {
    const currentUser = {
      uid: user.id,
      email: user.email,
      firstname: user.firstname,
      lastname: user.lastname,
      photoUrl: user.photo_url,
      admin: user.roles.includes('admin') || user.roles.includes('superuser'),
      superuser: user.roles.includes('superuser'),
      token: user.token,
    };
    hideLogin(currentUser);
    window.dispatchEvent(new Event('login'));
  };

  const handleLogin = async event => {
    event.preventDefault();
    const response = await loginUser({
      variables: { email: email, password: password },
    });
    const user = response.data.userAccount;
    if (user) {
      handleUser(user);
    } else {
      setMsg('Invalid Email or Password. Please try again.');
      showErrorMsg(true);
    }
  };

  const handleGoogleLogin = async () => {
    const response = await signInWithGooglePopup();
    if (response) {
      const result = await loginFirebaseUser({
        variables: { token: response.accessToken },
      });
      const user = result.data.userAccount;
      if (user) {
        handleUser(user);
      } else {
        // Redirect user to registration
        setRedirect(response);
        handleForm('register');
      }
    }
  };

  const handleUser = user => {
    if (user.expired) {
      setMsg(
        'This account has expired. If you require an extension or have any questions please contact us at skillup@cybera.ca'
      );
      showErrorMsg(true);
      return;
    }
    const { approved, roles } = user;
    if (approved || !featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY)) {
      login(user);
      if (
        (roles.includes('admin') || roles.includes('superuser')) &&
        navigateAdminSettings
      ) {
        navigate('/admin');
      } else if (featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY)) {
        navigate('/home');
      }
    } else if (approved === false) {
      setMsg(
        'We regret to inform you that your registration request has been rejected. If you have any questions please contact us at skillup@cybera.ca'
      );
      showErrorMsg(true);
      return;
    } else {
      setMsg(
        'This account is awaiting approval. If you have any questions please contact us at skillup@cybera.ca'
      );
      showErrorMsg(true);
      return;
    }
  };

  return redirect ? (
    <Form className={classes.container}>
      <Stack spacing={1}>
        <Skeleton variant="rounded" width="100%" height={70} />
        <Skeleton variant="rounded" width="100%" height={65} />
        <Skeleton variant="rounded" width="100%" height={65} />
        <Skeleton variant="rounded" width="100%" height={50} />
      </Stack>
    </Form>
  ) : (
    <Form className={classes.container}>
      <form>
        <TextField
          required
          value={email}
          label="Email"
          placeholder="Email"
          autoComplete="username"
          inputProps={{ inputMode: 'email' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          style={{ marginBottom: '20px', width: '100%' }}
          autoFocus={true}
          onChange={event => {
            setEmail(event.target.value);
            setMsg('');
            showErrorMsg(false);
          }}
          onKeyUp={eve => {
            if (eve.key === 'Enter') handleLogin(eve);
          }}
        />
        <PasswordInput
          label="Password"
          placeholder="Password"
          style={{ width: '100%' }}
          onChange={event => {
            setPassword(event.target.value);
            setMsg('');
            showErrorMsg(false);
          }}
          onKeyPress={eve => {
            if (eve.key === 'Enter') handleLogin(eve);
          }}
          tooltipIcons={{
            passwordLengthIcon: null,
            passwordLowercaseIcon: null,
            passwordUppercaseIcon: null,
            passwordNumberIcon: null,
            passwordSpecialCharacterIcon: null,
          }}
          passwordReset={false}
        />
      </form>
      <Button
        variant="contained"
        style={{ marginTop: '20px' }}
        onClick={handleLogin}
      >
        Log in
      </Button>
      <Grid
        container
        className={classes.textContainer}
        style={{ margin: '20px 0px' }}
      >
        <Typography
          className={classes.linkText}
          onClick={() => handleForm('forgot-password')}
        >
          Forgot password?
        </Typography>
      </Grid>
      <Divider>or</Divider>
      <Button
        variant="outlined"
        className={classes.googleButton}
        onClick={() => handleGoogleLogin()}
      >
        <img
          alt=""
          src="/assets/google-logo.png"
          style={{ marginRight: '5px' }}
          height={20}
          width={20}
        />
        Continue With Google
      </Button>
      <Button variant="text" onClick={() => handleForm('register')}>
        Don't have an account? Register for Access
      </Button>
    </Form>
  );
};
