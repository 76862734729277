import React, { useState } from 'react';

import {
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  useTheme,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import HttpsIcon from '@mui/icons-material/Https';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const PasswordInput = ({
  label,
  placeholder,
  style,
  onChange,
  onKeyPress,
  tooltipIcons,
  passwordReset,
}): JSX.Element => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleCopyPaste = e => {
    e.preventDefault();
  };

  const tooltipTheme = createTheme({
    ...theme, // Spread the old theme to inherit existing properties
    components: {
      ...theme.components, // Preserve existing component overrides if any
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            padding: '0',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.primary.main,
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32)) !important',
          },
          arrow: {
            color: theme.palette.primary.main,
          },
        },
      },
    },
  });

  return (
    <Box>
      {passwordReset ? (
        <ThemeProvider theme={tooltipTheme}>
          <Tooltip
            title={
              <React.Fragment>
                <div
                  style={{
                    padding: '10px',
                    color: theme.palette.white.main,
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  <Typography color="inherit">
                    The password must meet the following requirements:
                  </Typography>
                </div>
                <div
                  style={{
                    padding: '10px',
                    color: theme.palette.primary.main,
                  }}
                >
                  <List>
                    <ListItem>
                      {tooltipIcons.passwordLengthIcon}
                      <Typography>8 or more characters</Typography>
                    </ListItem>
                    <ListItem>
                      {tooltipIcons.passwordLowercaseIcon}
                      <Typography>Lowercase letter</Typography>
                    </ListItem>
                    <ListItem>
                      {tooltipIcons.passwordUppercaseIcon}
                      <Typography>Capital letter</Typography>
                    </ListItem>
                    <ListItem>
                      {tooltipIcons.passwordNumberIcon}
                      <Typography>Number</Typography>
                    </ListItem>
                    <ListItem>
                      {tooltipIcons.passwordSpecialCharacterIcon}
                      <Typography>Special character</Typography>
                    </ListItem>
                  </List>
                </div>
              </React.Fragment>
            }
            placement="bottom-start"
            arrow
            open={showTooltip}
            style={{
              maxWidth: 220,
              border: '1px solid #dadde9',
            }}
          >
            <TextField
              required
              label={label}
              placeholder={placeholder}
              type={showPassword ? 'text' : 'password'}
              style={style}
              autoComplete="current-password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <HttpsIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              onChange={onChange}
              onFocus={() => {
                setShowTooltip(true);
              }}
              onBlur={() => {
                setShowTooltip(false);
              }}
              onKeyPress={onKeyPress}
              onCopy={handleCopyPaste}
              onPaste={handleCopyPaste}
            />
          </Tooltip>
        </ThemeProvider>
      ) : (
        <TextField
          required
          label={label}
          placeholder={placeholder}
          type={showPassword ? 'text' : 'password'}
          style={style}
          autoComplete="current-password"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <HttpsIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          onChange={onChange}
          onFocus={() => {
            setShowTooltip(true);
          }}
          onBlur={() => {
            setShowTooltip(false);
          }}
          onKeyPress={onKeyPress}
        />
      )}
    </Box>
  );
};

export default PasswordInput;
