import React from 'react';

import { useMediaQuery } from '@mui/material';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ErrorImage from './error.svg';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    const { history } = this.props;
    history?.listen(() => {
      if (this.state.hasError) {
        this.setState({
          hasError: false,
        });
      }
    });
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  render() {
    if (this.state.hasError) {
      return <ErrorComponent />;
    }
    return this.props.children;
  }
}

const ErrorComponent = () => {
  const isSmallScreen = useMediaQuery(
    `(max-width:${localStorage.getItem('MD_SCREEN')})`
  );

  return (
    <Grid
      marginTop="100px"
      textAlign="center"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography variant="h2">Sorry, something went wrong.</Typography>
      <Typography variant="h6" sx={{ margin: '20px 0px' }}>
        Please refresh the page or try again later.
      </Typography>
      <img
        src={ErrorImage}
        alt="Something went wrong."
        style={{ width: isSmallScreen ? '90%' : '40%' }}
      />
    </Grid>
  );
};

export default ErrorBoundary;
