import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material';

const classes = {
  root: 'error-root',
};
const Root = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'block',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    margin: '0 auto',
    textAlign: 'center',
  },
}));
const ErrorBanner = ({ onRefresh } = {}) => {
  return (
    <Root className={classes.root}>
      <Typography variant="h4" gutterBottom>
        Something went wrong...
      </Typography>
      <Button variant="contained" onClick={onRefresh} color="primary">
        Try Reloading
      </Button>
    </Root>
  );
};

export default ErrorBanner;
