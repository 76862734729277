import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { styled } from '@mui/material';

const CustomDialogTitle = styled(DialogTitle)(({ color, theme }) => ({
  '&.MuiDialogTitle-root': {
    color: theme.palette[color].main + ' !important',
    textAlign: 'center',
  },
}));

export default function AlertDialog({
  title,
  body,
  yes = 'Yes',
  no = 'No',
  color = 'primary',
  open,
  onConfirm,
  onClose,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CustomDialogTitle color={color} variant="h5">
        {title}
      </CustomDialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color={color}
          variant="outlined"
          style={{ width: '100px' }}
        >
          {no}
        </Button>
        <Button
          onClick={onConfirm}
          color={color}
          variant="contained"
          style={{ width: '100px' }}
          autoFocus
        >
          {yes}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
