export const storeInput = data => {
  sessionStorage.setItem('goa-cyoa', JSON.stringify(data));
};

export const init = () => {
  if (!sessionStorage.getItem('goa-cyoa'))
    sessionStorage.setItem('goa-cyoa', JSON.stringify({}));
};

export const deleteInput = key => {
  const data = retrieveInput();
  if (data[key]) {
    delete data[key];
  }
  storeInput(data);
};

export const retrieveInput = () => {
  const data = sessionStorage.getItem('goa-cyoa');
  return data ? JSON.parse(data) : {};
};

export const clearCache = () => {
  sessionStorage.setItem('goa-cyoa', JSON.stringify({}));
};

export const storeDataItem = (key, value) => {
  const userData = retrieveInput();
  userData[key] = value;
  storeInput(userData);
};

export const retrieveDataItem = key => retrieveInput()[key];

const SELECTED_SKILLS_KEY = 'selectedSkills';
export const storeSelectedSkills = value =>
  storeDataItem(SELECTED_SKILLS_KEY, value);
export const retrieveSelectedSkills = () =>
  retrieveDataItem(SELECTED_SKILLS_KEY);

/****************************************************************************************************
 * Current Occupation Skills
 ***************************************************************************************************/
const CURRENT_SKILLS_KEY = 'currentSkills';
export const storeCurrentSkills = value =>
  storeDataItem(CURRENT_SKILLS_KEY, value);
export const retrieveCurrentSkills = () => retrieveDataItem(CURRENT_SKILLS_KEY);

const CURRENT_OCC_NAME = 'currentOccName';
export const storeCurrentOccName = value =>
  storeDataItem(CURRENT_OCC_NAME, value);
export const retrieveCurrentOccName = () => retrieveDataItem(CURRENT_OCC_NAME);

const CURRENT_AREA_NAME = 'currentAreaName';
export const storeCurrentAreaName = value =>
  storeDataItem(CURRENT_AREA_NAME, value);
export const retrieveCurrentAreaName = () =>
  retrieveDataItem(CURRENT_AREA_NAME);

export const deleteCurrentOccupation = () => {
  deleteInput(CURRENT_SKILLS_KEY);
  deleteInput(CURRENT_OCC_NAME);
  deleteInput(CURRENT_AREA_NAME);
};

/****************************************************************************************************
 * Previous Occupation Skills
 ***************************************************************************************************/
const PREVIOUS_SKILLS_KEY = 'previousSkills';
export const storePreviousSkills = value =>
  storeDataItem(PREVIOUS_SKILLS_KEY, value);
export const retrievePreviousSkills = () =>
  retrieveDataItem(PREVIOUS_SKILLS_KEY);

export const deletePreviousOccupations = () => {
  deleteInput(PREVIOUS_SKILLS_KEY);
};

export const deletePreviousOccupation = occKey => {
  const data = retrieveInput();
  if (data[PREVIOUS_SKILLS_KEY][occKey]) {
    delete data[PREVIOUS_SKILLS_KEY][occKey];
  }
  storeInput(data);
};

/****************************************************************************************************
 * Soft Skills
 ***************************************************************************************************/
const SOFT_SKILLS_KEY = 'softSkills';
export const storeSoftSkills = value => storeDataItem(SOFT_SKILLS_KEY, value);
export const retrieveSoftSkills = () => retrieveDataItem(SOFT_SKILLS_KEY);

/****************************************************************************************************
 * Resume Skills
 ***************************************************************************************************/
const RESUME_SKILLS_KEY = 'resumeSkills';
export const storeResumeSkills = value =>
  storeDataItem(RESUME_SKILLS_KEY, value);
export const retrieveResumeSkills = () => retrieveDataItem(RESUME_SKILLS_KEY);

const RESUME_FILE_NAME = 'resumeFileName';
export const storeResumeFileName = value =>
  storeDataItem(RESUME_FILE_NAME, value);
export const retrieveResumeFileName = () => retrieveDataItem(RESUME_FILE_NAME);

export const deleteResumeFile = () => {
  // Remove both the file and the list of skills
  deleteInput(RESUME_FILE_NAME);
  deleteInput(RESUME_SKILLS_KEY);
};
