import React, { createContext, useState, useContext } from 'react';

const SkillsModalContext = createContext();

export const SkillsModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return (
    <SkillsModalContext.Provider value={{ open, openModal, closeModal }}>
      {children}
    </SkillsModalContext.Provider>
  );
};

// Custom hook to use the modal context
export const useSkillsModal = () => useContext(SkillsModalContext);
