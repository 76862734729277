import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    return JSON.parse(sessionStorage.getItem('isUserLoggedIn'));
  };

  const [token, setToken] = useState(getToken());

  const saveToken = login => {
    sessionStorage.setItem('isUserLoggedIn', JSON.stringify(login));
    setToken(login);
  };

  return {
    setToken: saveToken,
    token,
  };
}
