import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.10)',
  },
  spinner: {
    outline: 'none',
  },
});

export const ModalSpinner = ({ showIcon = true }) => {
  const classes = useStyles();

  return (
    <Modal open={true} className={classes.root} hideBackdrop={true}>
      <CircularProgress
        className={classes.spinner}
        style={!showIcon ? { visibility: 'hidden' } : {}}
      />
    </Modal>
  );
};
