import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';

const PREFIX = 'BreadcrumbHeader';

export const classes = {
  banner: `${PREFIX}-banner`,
  container: `${PREFIX}-container`,
  button: `${PREFIX}-button`,
  breadcrumb: `${PREFIX}-breadcrumb`,
  breadcrumbLink: `${PREFIX}-breadcrumbLink`,
  tutorialContainer: `${PREFIX}-tutorialContainer`,
  tutorialButton: `${PREFIX}-tutorialButton`,
};

export const Root = styled(Container)(({ theme }) => ({
  [`&.${classes.banner}`]: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.secondary,
    width: '100%',
    maxWidth: '100%',
    '@media (max-width: 1140px)': {
      marginTop: '0px',
    },
  },
  [`& .${classes.container}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: '1600px',
  },
  [`& .${classes.button}`]: {
    color: theme.palette.primary.main,
    margin: '8px 0px',
    padding: '2px 8px 2px 4px',
  },
  [`& .${classes.breadcrumb}`]: {
    display: 'flex',
    marginLeft: '20px',
    color: theme.palette.primary.contrast,
    '& .MuiBreadcrumbs-separator': {
      color: theme.palette.primary.contrast,
    },
  },
  [`& .${classes.breadcrumbLink}`]: {
    '&:visited': {
      color: theme.palette.primary.contrast,
    },
  },
  [`& .${classes.tutorialContainer}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.tutorialButton}`]: {
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 450,
    color: theme.palette.primary.contrast,
  },
}));
