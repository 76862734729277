import { styled } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';

const PREFIX = 'WebHeader';

export const classes = {
  toolbar: `${PREFIX}-toolbar`,
  leftSection: `${PREFIX}-leftSection`,
  rightSection: `${PREFIX}-rightSection`,
  logo: `${PREFIX}-logo`,
  linkParent: `${PREFIX}-linkParent`,
  link: `${PREFIX}-link`,
  header: `${PREFIX}-header`,
  headerButton: `${PREFIX}-headerButton`,
  headerButtonOpen: `${PREFIX}-headerButtonOpen`,
  downIcon: `${PREFIX}-downIcon`,
  desktopUserName: `${PREFIX}-desktopUserName`,
  desktopUserNameOpen: `${PREFIX}-desktopUserNameOpen`,
  logoutIcon: `${PREFIX}-logoutIcon`,
  displayName: `${PREFIX}-displayName`,
};

export const WebBase = styled(Toolbar)(({ theme }) => ({
  [`&.${classes.toolbar}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '80px',
    padding: '0px',
    maxWidth: '1600px',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default,
    '@media (min-width: 1140px) and (max-width: 1658px)': {
      padding: '0px 24px',
    },
  },
  [`& .${classes.leftSection}`]: {
    display: 'flex',
  },
  [`& .${classes.rightSection}`]: {
    display: 'flex',
    minWidth: '130px',
    justifyContent: 'flex-end',
    maxHeight: '50px',
  },
  [`& .${classes.logo}`]: {
    flexGrow: 1,
    color: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  [`& .${classes.linkParent}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: '40px',
  },
  [`& .${classes.link}`]: {
    textDecoration: 'none',
  },
  [`& .${classes.header}`]: {
    fontSize: '18px',
    fontWeight: 500,
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  [`& .${classes.headerButton}`]: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px !important',
    paddingRight: '8px',
    marginRight: '12px',
    height: '50px',
    boxSizing: 'border-box',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: theme.palette.blue.light,
    },
    '@media (max-width: 1200px)': {
      marginRight: '20px',
    },
  },
  [`& .${classes.headerButtonOpen}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px !important',
    marginRight: '12px',
    height: '50px',
    boxSizing: 'border-box',
    borderRadius: '8px',
    backgroundColor: theme.palette.blue.light,
    '@media (max-width: 1200px)': {
      marginRight: '20px',
    },
  },
  [`& .${classes.downIcon}`]: {
    position: 'relative',
    top: '1px',
    color: theme.palette.primary.main,
  },
  [`& .${classes.desktopUserName}`]: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    height: '50px',
    padding: '8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.blue.light,
    },
  },
  [`& .${classes.desktopUserNameOpen}`]: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    height: '50px',
    padding: '8px',
    cursor: 'pointer',
    backgroundColor: theme.palette.blue.light,
  },
  [`& .${classes.logoutIcon}`]: {
    paddingLeft: '15px',
  },
  [`& .${classes.displayName}`]: {
    maxWidth: '170px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginRight: '5px',
    fontSize: '18px',
    fontWeight: 500,
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
