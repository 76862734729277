import React from 'react';
import { Link } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

const Dropdown = ({ items }) => {
  return items.map((menu, index) => {
    return (
      <React.Fragment key={index}>
        <MenuItem component={Link} to={menu.link}>
          <Typography>{menu.title}</Typography>
        </MenuItem>
        {index !== items.length - 1 && <Divider />}
      </React.Fragment>
    );
  });
};

export default Dropdown;
