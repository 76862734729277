export const PAGES = {
  HOME: 'Home Page',
  EXPLORE: 'Explore Page',
  GROWTH: 'Growth Page',
  DEMAND: 'Demand Page',
  TRAINING: 'Training Page',
  INTEREST_FILTER: 'Interest Filter Page',
  WORKING_IN_ALBERTA: 'Working in AB Page',
  SKILLS_EXPLORER: 'Skills Explorer Page',
  ABOUT: 'About Page',
  ADMIN: 'Admin Page',
  PRIVACY: 'Privacy Page',
  TERMS: 'Terms Page',
  FAVOURITES: 'Favourites Page',
  RESET_PASSWORD: 'Reset Password Page',
  LOGIN: 'Login Page',
  REGISTER: 'Register Page',
  FORGOT_PASSWORD: 'Forgot Password Page',
  RELEASE: 'Release Notes Page',
  LANDING: 'Landing Page',
};
