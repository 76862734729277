import { styled } from '@mui/material';
import { keyframes } from '@mui/material';

const PREFIX = 'Maintenance';

export const classes = {
  background: `${PREFIX}-background`,
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  iconText: `${PREFIX}-iconText`,
  iconGraphic: `${PREFIX}-iconGraphic`,
};

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.background}`]: {
    display: 'flex',
  },
  [`& .${classes.root}`]: {
    paddingTop: theme.spacing(10), // avoids overlap with header component
    '@media (max-width: 1140px)': {
      paddingTop: '0px',
    },
  },
  [`& .${classes.title}`]: {
    fontWeight: 600,
    marginTop: '10px',
    display: 'inline-flex',
    alignItems: 'flex-end',
    '@media (max-width: 330px)': {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  [`& .${classes.subtitle}`]: {
    margin: '30px 20px 30px 0px',
    '@media (max-width: 900px)': {
      margin: '20px 0px',
    },
  },
  [`& .${classes.iconText}`]: {
    padding: '0px 8px',
  },
  [`& .${classes.iconGraphic}`]: {
    height: '60%',
    width: '60%',
    '@media (max-width: 900px)': {
      height: '99px',
      width: '68px',
      marginBottom: '99px',
    },
    animation: `${spin} 4s ease`,
    animationFillMode: 'forwards',
  },
}));

const spin = keyframes`  
  0%{
    transform-origin: bottom center; 
    transform: rotate(0deg);
  }
  5%{
    transform-origin: bottom center; 
    transform: rotate(-5deg);
  }
  10%{
    transform-origin: bottom center; 
    transform: rotate(5deg);
  }
  15%{
    transform-origin: bottom center; 
    transform: rotate(-10deg);
  }
  25%{
    transform-origin: bottom center; 
    transform: rotate(10deg);
  }
  40% {
    transform-origin: bottom center;
    transform: rotate(-270deg);
  }
  45% {
    transform-origin: bottom center;
    transform: rotate(-225deg);
  }
  50% {
    transform-origin: bottom center;
    transform: rotate(-260deg);
  }
  55% {
    transform-origin: bottom center;
    transform: rotate(-225deg);
  }
  60% {
    transform-origin: bottom center;
    transform: rotate(-250deg);
  }
  65% {
    transform-origin: bottom center;
    transform: rotate(-225deg);
  }
  70% {
    transform-origin: bottom center;
    transform: rotate(-240deg);
  }
  75% {
    transform-origin: bottom center;
    transform: rotate(-225deg);
  }
  80% {
    transform-origin: bottom center;
    transform: rotate(-230deg);
  }
  85% {
    transform-origin: bottom center;
    transform: rotate(-225deg);
  }
  100% {
    transform-origin: bottom center;
    transform: rotate(-225deg);
  }
`;
