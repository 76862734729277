import React, { useState } from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Root, classes } from './style';

export const Maintenance = () => {
  const [imageIsReady, setImageIsReady] = useState(false);

  const logoGraphic = '/assets/skill-up-logo-graphic-only.svg';
  const logoText = '/assets/skill-up-logo-text-only.svg';
  const image = new Image();
  image.src = logoGraphic;
  image.onload = () => {
    setImageIsReady(true);
  };

  const isMediumScreen = useMediaQuery(
    `(min-width:${localStorage.getItem('SM_SCREEN')})`
  );

  return imageIsReady ? (
    <Root className={classes.background}>
      <Container className={classes.root}>
        <Grid container flexDirection="row" alignItems="center">
          <Grid item xs={12} md={4} display="flex" justifyContent="center">
            <img
              src={logoGraphic}
              alt="Skill Up Logo"
              className={classes.iconGraphic}
            ></img>
          </Grid>
          <Grid item xs={12} md={8} display="flex" flexDirection="column">
            <Typography
              className={classes.title}
              variant={isMediumScreen ? 'h4' : 'h5'}
            >
              Sorry,{' '}
              <img
                alt="Skill-Up Logo"
                className={classes.iconText}
                src={logoText}
                height={'40px'}
                width={'100px'}
              ></img>{' '}
              is... down.
            </Typography>
            <Typography variant={'subtitle1'} className={classes.subtitle}>
              We are updating our database so that you are always using the most
              current information available. This usually takes a couple of
              hours. You will be back planning for your future in no time!
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Root>
  ) : (
    <></>
  );
};
