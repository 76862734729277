export const ROUTE = {
  HOME: 'home',
  EXPLORE: 'explore',
  GROWTH: 'growth',
  DEMAND: 'demand',
  TRAINING: 'training',
  INTEREST_FILTER: 'interests-quiz',
  WORKING_IN_ALBERTA: 'working-in-ab',
  SKILLS_EXPLORER: 'skills-explorer',
  ABOUT: 'about',
  ADMIN: 'admin',
  PRIVACY: 'privacy',
  TERMS: 'terms',
  FAVOURITES: 'favourites',
  RESET_PASSWORD: 'reset_password/:email/:token',
  LOGIN: 'login',
  REGISTER: 'register',
  FORGOT_PASSWORD: 'forgot-password',
  RELEASE: 'change-log',
};
