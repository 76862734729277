import React from 'react';

import { Typography, Button } from '@mui/material';

import featureFlagManager, { FLAG } from 'modules/featureFlagManager';

export const RegisterSuccess = ({ handleForm, setRedirect }) => {
  const handleReturn = () => {
    setRedirect(null);
    handleForm('login');
  };

  return !featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY) ? (
    <>
      <Typography
        variant="subtitle1"
        sx={{ color: 'grey', fontWeight: 450, marginBottom: '10px' }}
      >
        Thank you for registering with SkillUp!
      </Typography>
      <Typography variant="body2" sx={{ color: 'grey', marginBottom: '10px' }}>
        You can now log in to your account. If you registered with an email
        address for the first time, please check your inbox for instructions on
        setting up your password.
      </Typography>
      <Typography variant="body2" sx={{ color: 'grey', marginBottom: '10px' }}>
        If you have any questions or need assistance in getting started, please
        don't hesitate to contact us at skillup@cybera.ca.
      </Typography>
      <Typography variant="body2" sx={{ color: 'grey' }}>
        Best regards, the SkillUp team
      </Typography>
      <Button
        variant="contained"
        sx={{ marginTop: '20px' }}
        onClick={handleReturn}
      >
        Back to log in
      </Button>
    </>
  ) : (
    <>
      <Typography
        variant="subtitle1"
        sx={{ color: 'grey', fontWeight: 450, marginBottom: '10px' }}
      >
        Thank you for registering with SkillUp!
      </Typography>
      <Typography variant="body2" sx={{ color: 'grey', marginBottom: '10px' }}>
        Your request for access has been received. Our team will review your
        submission, and you will be notified once your account is approved.
      </Typography>
      <Typography variant="body2" sx={{ color: 'grey', marginBottom: '10px' }}>
        If you have any questions, please contact skillup@cybera.ca.
      </Typography>
      <Typography variant="body2" sx={{ color: 'grey' }}>
        We appreciate your patience.
      </Typography>
    </>
  );
};
