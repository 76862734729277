import React, { useEffect, useState } from 'react';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let user = JSON.parse(sessionStorage.getItem('isUserLoggedIn'));
    setLoading(false);
    if (user) {
      setCurrentUser(user);
    }
  }, []);

  window.addEventListener('login', () => {
    let user = JSON.parse(sessionStorage.getItem('isUserLoggedIn'));
    setCurrentUser(user);
  });

  return (
    <AuthContext.Provider
      value={{
        loading,
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
