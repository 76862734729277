import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    textAlign: 'center',
    marginTop: theme.spacing(4),
    zIndex: 20,
  },
  fullPage: {
    zIndex: 1,
    top: 0,
    position: 'fixed',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      position: 'relative',
      top: '-10%',
    },
  },
  container: {
    display: 'flex',
    paddingLeft: 10,
    paddingRight: 10,
  },
  center: {
    justifyContent: 'center',
  },
  left: {
    justifyContent: 'flex-start',
    paddingLeft: 20,
  },
  right: {
    justifyContent: 'flex-end',
  },
}));

export const LoadingSpinner = ({ fullPage, align = 'center' }) => {
  const classes = useStyles();

  return fullPage ? (
    <div className={`${classes.fullPage} ${classes[align]}`}>
      <div className={classes.root}>
        <CircularProgress />
        <Typography variant="h4">Loading</Typography>
      </div>
    </div>
  ) : (
    <div className={`${classes.container} ${classes[align]}`}>
      <div className={classes.root}>
        <CircularProgress />
        <Typography variant="h4">Loading</Typography>
      </div>
    </div>
  );
};
