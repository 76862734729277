import { useState } from 'react';

// This hook is responsible of handling any email state and validating it if needed
const useEmailValidation = initialEmail => {
  const [email, setEmail] = useState(initialEmail);
  const [emailError, setEmailError] = useState('');

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      setEmailError('Please enter a valid email address');
      return false;
    }
    setEmailError('');
    return true;
  };

  return {
    email,
    setEmail,
    emailError,
    validateEmail,
  };
};

export default useEmailValidation;
