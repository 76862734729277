import React, { createContext, useState, useContext } from 'react';

const InterestsModalContext = createContext();

export const InterestsModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return (
    <InterestsModalContext.Provider value={{ open, openModal, closeModal }}>
      {children}
    </InterestsModalContext.Provider>
  );
};

// Custom hook to use the modal context
export const useInterestsModal = () => useContext(InterestsModalContext);
