import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
  PropsWithChildren,
} from 'react';
import { useBlocker } from 'react-router-dom';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { AuthContext } from 'Auth';
import { LoginModalContext } from 'modules/loginModalContext/LoginModal';

import { classes, SignInPopover } from './style';
import { FixMeLater } from 'any';

// Default position of this component is to show a prompt when a user is not logged in,
// however if you provide the props showOnExit = true then the component will show the
// prompt only when the user is navigating away from the current tool (ie Interests Quiz)
// - toggle prop is used to show the prompt more often as needed by the parent component.
// - onClose prop is used to perform action by parent when popup is closed if needed.

export const LOGIN_BUTTON_ID: string = 'skillup-login-button';

interface SignInPromptProps {
  showOnExit: boolean;
  toggle: boolean;
  onClose: (() => void) | false;
}

export const SignInPrompt = ({
  showOnExit = false,
  toggle = false,
  onClose = false,
  children,
}: PropsWithChildren<SignInPromptProps>) => {
  const { currentUser }: FixMeLater = useContext(AuthContext);
  const { openLoginModal }: FixMeLater = useContext(LoginModalContext);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const shouldBlock = useCallback(
    ({
      currentLocation,
      nextLocation,
    }: {
      currentLocation: any;
      nextLocation: any;
    }): boolean => {
      if (showOnExit) {
        return (
          !currentUser &&
          currentLocation.pathname.split('/')[1] !==
            nextLocation.pathname.split('/')[1]
        );
      }
      return false;
    },
    [currentUser, showOnExit]
  );

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === 'blocked' || (!showOnExit && !currentUser)) {
      const anchor: HTMLElement | null =
        document.getElementById(LOGIN_BUTTON_ID);
      setAnchorEl(anchor);
      if (anchor && anchor.getBoundingClientRect().top >= 0) {
        setOpen(true);
      } else {
        window.dispatchEvent(new Event('revealHeader'));
        setTimeout(() => {
          setOpen(true);
        }, 600);
      }
    }
  }, [blocker, showOnExit, currentUser, toggle]);

  const handleClose = (): void => {
    setOpen(false);
    if (onClose) onClose();
  };

  const handleSubmit = (): void => {
    setOpen(false);
    openLoginModal('login');
  };

  const handleDiscard = (): void => {
    if (blocker.proceed) blocker.proceed();
  };

  return (
    <>
      <SignInPopover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 70,
          horizontal: 93,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transitionDuration={250}
        open={open && anchorEl !== null}
        elevation={10}
      >
        <Card className={classes.promptBody} elevation={0}>
          <CardHeader
            className={classes.promptHeader}
            action={
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent className={classes.promptContent}>
            {children}
            <Typography
              className={children ? undefined : classes.promptContentNoChildren}
            >
              Want to store your saved items, preferences and make your career
              exploration easier?
            </Typography>
          </CardContent>
          <CardActions className={classes.promptActions}>
            {showOnExit && (
              <Button
                className={classes.promptDiscardButton}
                variant="outlined"
                onClick={handleDiscard}
              >
                Continue
              </Button>
            )}
            <Button
              className={classes.promptButton}
              variant="contained"
              onClick={handleSubmit}
            >
              Log In
            </Button>
          </CardActions>
        </Card>
      </SignInPopover>
    </>
  );
};
