export const SESSION_KEYS = {
  HOME: 'home',
  INTERESTS_QUIZ: 'interests-quiz',
  SKILLS_EXPLORER: 'skills-explorer',
};

export const SESSION_TYPE = {
  VISITED: 'VISITED',
  PREFS: 'PREFS',
};

const getSessionItem = source => {
  return JSON.parse(sessionStorage.getItem(source)) || {};
};

export const updateUserHistory = ({ storageType, source }) => {
  const session = getSessionItem(storageType);
  sessionStorage.setItem(
    storageType,
    JSON.stringify({ ...session, [source]: true })
  );
};

export const getUserHistory = ({ storageType, source }) => {
  const visited = getSessionItem(storageType);
  return visited?.[source];
};

export const clearSession = ({ sessionType }) => {
  sessionStorage.removeItem(SESSION_TYPE[sessionType]);
  sessionStorage.removeItem('goa-cyoa');
};
