import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  content: {
    backgroundColor: theme.palette.primary.secondary,
    color: theme.palette.text.main,
    minHeight: '50px',
    padding: '10px 0px 30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.default,
    width: '40%',
    borderRadius: 8,
    boxShadow: 24,
    padding: '15px 25px 25px 25px',
    '@media (max-width: 900px)': {
      width: '80%',
    },
  },
  page: {
    padding: '150px 40px 20px',
    alignSelf: 'center',
    '@media (max-width: 1140px)': {
      padding: '20px 40px',
    },
    '@media (max-width: 600px)': {
      padding: '20px',
    },
  },
  close: {
    position: 'absolute',
    right: '10px',
  },
  title: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontWeight: 500,
    display: 'block',
    paddingBottom: 25,
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    display: 'block',
    paddingBottom: 10,
  },
  note: {
    color: theme.palette.grey.main,
    fontWeight: 500,
    fontSize: '0.85rem',
    display: 'block',
    paddingBottom: 10,
  },
  bulletPoint: {
    paddingBottom: 10,
  },
  link: {
    color: theme.palette.primary.main,
  },
  text: {
    maxHeight: 600,
    overflowY: 'scroll',
  },
  typography: {
    paddingBottom: '8px',
    display: 'block',
  },
  onetLink: {
    color: theme.palette.primary.contrast,
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  releaseItem: {
    padding: '8px',
    marginBottom: '16px',
  },
  releaseChip: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  releaseDate: {
    color: theme.palette.grey.main,
  },
  whatsNewBox: {
    marginBottom: '24px',
    background: theme.palette.primary.main,
    color: 'white',
    padding: '20px',
  },
}));
