import { FAV_KEY } from 'models/Favourites';

// Add new options when creating new favourite implementations to all 3 strategies.
export const favouritesRemovalStrategy = {
  [FAV_KEY.OCCUPATIONS]: occupationsRemovalStrategy,
  [FAV_KEY.JOB_TRANSITIONS]: jobTransitionRemovalStrategy,
};

export const favouritesCheckStrategy = {
  [FAV_KEY.OCCUPATIONS]: occupationsCheckStrategy,
  [FAV_KEY.JOB_TRANSITIONS]: jobTransitionCheckStrategy,
};

export const favouritesInsertStrategy = {
  [FAV_KEY.OCCUPATIONS]: occupationsInsertStrategy,
  [FAV_KEY.JOB_TRANSITIONS]: jobTransitionInsertStrategy,
};

//
// Occupations Favourites functions
//
function occupationsCheckStrategy(favs, detailToCheck) {
  return favs?.find(fav => fav.lightcastId === detailToCheck.lightcastId);
}

function occupationsRemovalStrategy(favs, detailToRemove) {
  return favs.filter(fav => fav.lightcastId !== detailToRemove.lightcastId);
}

function occupationsInsertStrategy(favs, detailToInsert) {
  if (occupationsCheckStrategy(favs, detailToInsert)) {
    return favs;
  }
  return [...(favs || []), detailToInsert];
}

//
// Skill Gap Favourites functions
//
function jobTransitionCheckStrategy(favs, detailToCheck) {
  return favs?.find(
    fav =>
      fav.currentId === detailToCheck.currentId &&
      fav.targetId === detailToCheck.targetId
  );
}

function jobTransitionRemovalStrategy(favs, detailToRemove) {
  const filters = ['currentId', 'targetId'];
  return favs.filter(fav => {
    return !filters.every(filter => fav[filter] === detailToRemove[filter]);
  });
}

function jobTransitionInsertStrategy(favs, detailToInsert) {
  if (jobTransitionCheckStrategy(favs, detailToInsert)) {
    return favs;
  }
  return [...(favs || []), detailToInsert];
}
