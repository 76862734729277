import React, { useEffect, useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import useMediaQuery from '@mui/material/useMediaQuery';

import Typography from '@mui/material/Typography';
import { Button, Modal, Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';

import useStyles from './style';
import PrivacyStatement from './links/PrivacyStatement';
import TermsOfUse from './links/TermsOfUse';
import featureFlagManager, { FLAG } from 'modules/featureFlagManager';

import { PHOTON_GRAPH_URL } from 'environmentConfig';

const GET_RELEASE_VERSION = gql`
  query getVersion {
    releaseDetails(orderBy: ID_DESC, first: 1) {
      nodes {
        releaseVersion
      }
    }
  }
`;
const GET_PREVIEW_MESSAGE = gql`
  query GetPreviewMessage {
    featureFlags(condition: { feature: "public_preview" }) {
      nodes {
        message
      }
    }
  }
`;

const Footer = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const [previewMessageLoaded, setPreviewMessageLoaded] = useState(false);
  const [previewMessage, setPreviewMessage] = useState('');
  const [version, setVersion] = useState('');
  const [imageIsReady, setImageIsReady] = useState(false);

  const logoSource = '/assets/cybera-colour-logo.svg';
  const image = new Image();
  image.src = logoSource;
  image.onload = () => {
    setImageIsReady(true);
  };

  const [loadPreviewMessage] = useLazyQuery(GET_PREVIEW_MESSAGE, {
    context: {
      uri: PHOTON_GRAPH_URL,
    },
  });
  const [release] = useLazyQuery(GET_RELEASE_VERSION, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  const handleClose = () => setOpen(false);

  const getVersion = () => {
    release({
      onCompleted: data =>
        setVersion(data.releaseDetails.nodes[0].releaseVersion),
    });
  };

  useEffect(() => {
    setPreviewMessageLoaded(false);
    loadPreviewMessage({
      onCompleted: data => {
        setPreviewMessage(data.featureFlags.nodes[0].message);
        setPreviewMessageLoaded(true);
      },
    });
  }, []);

  useEffect(() => {
    getVersion();
  }, [release]);

  const isMobile = useMediaQuery(
    `(max-width:${localStorage.getItem('MD_SCREEN')})`
  );

  return (
    <div elevation={3} style={{ flexShrink: 0 }}>
      <div className={classes.content}>
        <Container>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <Typography className={classes.typography} variant="subtitle2">
                This site uses information from Alberta-based job ads gathered
                daily and provided by&nbsp;
                <a
                  href="https://lightcast.io/about/data"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.onetLink}
                >
                  Lightcast
                </a>
              </Typography>
              {featureFlagManager.featureEnabled(FLAG.PUBLIC_PREVIEW) &&
                previewMessageLoaded && (
                  <Typography
                    className={classes.typography}
                    style={{ width: isMobile ? 'auto' : '85%' }}
                    variant="subtitle2"
                  >
                    {previewMessage}
                  </Typography>
                )}
              <Typography
                className={classes.typography}
                style={{ width: isMobile ? 'auto' : '85%' }}
                variant="subtitle2"
              >
                Contact us at{' '}
                <a href="mailto:skillup@cybera.ca" className={classes.onetLink}>
                  skillup@cybera.ca
                </a>
              </Typography>
              {!isMobile && (
                <Typography className={classes.typography} variant="subtitle2">
                  © 2024&nbsp;
                  <a
                    href="https://www.cybera.ca/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.onetLink}
                  >
                    Cybera
                  </a>
                  .&nbsp;All rights reserved.
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {isMobile && (
                <Divider
                  sx={{ marginBottom: '20px' }}
                  variant="middle"
                  flexItem
                />
              )}
              <Typography className={classes.typography} variant="subtitle2">
                Developed and run by
              </Typography>
              {imageIsReady && (
                <img
                  src={logoSource}
                  alt="CYBERA"
                  style={{ height: '80px', marginBottom: '20px' }}
                />
              )}
              <Typography className={classes.typography} variant="subtitle2">
                Last updated{' '}
                {localStorage.getItem('REFRESH_DATE') === 'null'
                  ? 'N/A'
                  : localStorage.getItem('REFRESH_DATE')}
              </Typography>
              <Grid container>
                <Typography className={classes.typography} variant="subtitle2">
                  <a
                    onClick={() => navigate('/change-log')}
                    className={classes.onetLink}
                  >
                    {version}
                  </a>
                </Typography>
                <Typography style={{ margin: '0px 5px' }} variant="subtitle2">
                  |
                </Typography>
                <Button
                  className={classes.typography}
                  variant="text"
                  style={{
                    textTransform: 'none',
                    paddingLeft: '0',
                    paddingTop: '0',
                  }}
                  onClick={() => {
                    setText('Privacy Statement');
                    setOpen(true);
                  }}
                >
                  <Typography className={classes.onetLink} variant="subtitle2">
                    Privacy Statement
                  </Typography>
                </Button>
                <Typography style={{ marginRight: '5px' }} variant="subtitle2">
                  |
                </Typography>
                <Button
                  className={classes.typography}
                  variant="text"
                  style={{
                    textTransform: 'none',
                    paddingLeft: '0',
                    paddingTop: '0',
                  }}
                  onClick={() => {
                    setText('Terms of Use');
                    setOpen(true);
                  }}
                >
                  <Typography className={classes.onetLink} variant="subtitle2">
                    Terms of Use
                  </Typography>
                </Button>
              </Grid>
              {isMobile && (
                <Typography className={classes.typography} variant="subtitle2">
                  © 2024&nbsp;
                  <a
                    href="https://www.cybera.ca/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.onetLink}
                  >
                    Cybera
                  </a>
                  .&nbsp;All rights reserved.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modal}>
          <IconButton
            className={classes.close}
            aria-label="close"
            size="small"
            onClick={() => setOpen(false)}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
          <Typography className={classes.title} variant="h5">
            {text}
          </Typography>
          <div className={classes.text}>
            {text === 'Privacy Statement' ? (
              <PrivacyStatement modal={true} />
            ) : (
              <TermsOfUse modal={true} />
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Footer;
