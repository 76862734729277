import React from 'react';

import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import { Root, classes } from './style';
import { useSkillsModal } from '../../../modules/welcomeModalContext/SkillsModal';
import { useInterestsModal } from '../../../modules/welcomeModalContext/InterestsModal';
import { BackButton } from 'components/ui';

const pathToNameMapping = {
  home: 'Home',
  about: 'About',
  explore: 'Explore All Occupations',
  growth: 'Fastest Growing Occupations',
  demand: 'In Demand Occupations',
  'skills-explorer': 'Skills Explorer',
  'interests-quiz': 'Interests Quiz',
  'working-in-ab': 'Working In Alberta',
  favourites: 'My Favourites',
  privacy: 'Privacy Statement',
  terms: 'Terms Of Use',
};

export const Breadcrumb = () => {
  const { openModal: openSkillsModal } = useSkillsModal();
  const { openModal: openInterestsModal } = useInterestsModal();
  const { pathname } = useLocation();
  const current = pathname.split('/').filter(crumb => crumb !== '')[0];

  const isSmallScreen = useMediaQuery(
    `(max-width:${localStorage.getItem('MD_SCREEN')})`
  );

  if (
    pathname === '/' ||
    pathname === '/home' ||
    pathname === '/login' ||
    pathname === '/register' ||
    pathname === '/forgot-password' ||
    pathname === '/admin' ||
    pathname.includes('reset_password')
  )
    return null;

  return (
    <Root className={classes.banner}>
      <Grid container className={classes.container}>
        <Grid item sx={{ display: 'flex' }}>
          <BackButton />
          <Breadcrumbs
            className={classes.breadcrumb}
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Typography sx={{ fontWeight: 450 }}>
              {pathToNameMapping[current]}
            </Typography>
          </Breadcrumbs>
        </Grid>
        {(current === 'skills-explorer' || current === 'interests-quiz') && (
          <Grid item className={classes.tutorialContainer}>
            {isSmallScreen ? (
              <IconButton
                className={classes.tutorialButton}
                onClick={
                  current === 'skills-explorer'
                    ? openSkillsModal
                    : openInterestsModal
                }
              >
                <HelpOutlineIcon />
              </IconButton>
            ) : (
              <Tooltip
                title={`Learn more about how to use the ${pathToNameMapping[current]}`}
                placement="bottom-end"
                arrow
              >
                <Button
                  variant="text"
                  endIcon={<HelpOutlineIcon />}
                  className={classes.tutorialButton}
                  onClick={
                    current === 'skills-explorer'
                      ? openSkillsModal
                      : openInterestsModal
                  }
                >
                  Walkthrough
                </Button>
              </Tooltip>
            )}
          </Grid>
        )}
      </Grid>
    </Root>
  );
};
