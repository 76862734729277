import React, { useContext, useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DownIcon from '@mui/icons-material/ArrowDropDown';
import UpIcon from '@mui/icons-material/ArrowDropUp';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';

import featureFlagManager, { FLAG } from 'modules/featureFlagManager';

import { menuItems } from '../content.js';
import Dropdown from './dropdown.js';
import { classes, WebBase } from './style';
import AccountDropdown from './accountDropdown.js';
import { LoginModalContext } from 'modules/loginModalContext/LoginModal';
import { LOGIN_BUTTON_ID } from 'modules/signInPrompt/index.tsx';

const WebMenu = ({ logoSource, darkMode, handleThemeToggle }) => {
  const { openLoginModal } = useContext(LoginModalContext);
  const location = useLocation();

  const [loggedIn, setLoggedIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openDropdown = Boolean(anchorEl);

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  useEffect(() => {
    if (!loggedIn) {
      const user = JSON.parse(sessionStorage.getItem('isUserLoggedIn'));
      setLoggedIn(user ? true : false);
    }
  }, [location]);

  return (
    <WebBase className={classes.toolbar}>
      <div className={classes.leftSection}>
        <Typography variant="h5" noWrap className={classes.logo}>
          <Link to="/home">
            <img
              src={logoSource}
              alt="SKILL-UP"
              style={{ height: '50px' }}
            ></img>
          </Link>
        </Typography>
      </div>
      {(loggedIn || !featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY)) && (
        <Grid container className={classes.linkParent} columnSpacing={4}>
          {menuItems.map(({ title, link, subMenu }, index) => {
            return (
              !(
                !featureFlagManager.featureEnabled(
                  FLAG.WORKING_IN_ALBERTA_FLAG
                ) && title === 'Working in Alberta'
              ) &&
              (subMenu ? (
                <Grid
                  item
                  key={index}
                  className={
                    openDropdown
                      ? classes.headerButtonOpen
                      : classes.headerButton
                  }
                  onClick={handleClick}
                >
                  <Typography className={classes.header}>{title}</Typography>
                  {openDropdown ? (
                    <UpIcon className={classes.downIcon} />
                  ) : (
                    <DownIcon className={classes.downIcon} />
                  )}
                  {openDropdown && (
                    <Menu
                      keepMounted
                      anchorEl={anchorEl}
                      open={openDropdown}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{
                        horizontal: 'right',
                        vertical: 'top',
                      }}
                      anchorOrigin={{
                        horizontal: 'right',
                        vertical: 'bottom',
                      }}
                    >
                      <Dropdown items={subMenu} />
                    </Menu>
                  )}
                </Grid>
              ) : (
                <Grid item key={index} className={classes.headerButton}>
                  <Link to={link} className={classes.link}>
                    <Typography className={classes.header}>{title}</Typography>
                  </Link>
                </Grid>
              ))
            );
          })}
        </Grid>
      )}
      <div className={classes.rightSection}>
        <IconButton
          style={{ marginRight: '10px', width: '50px' }}
          onClick={() => {
            handleThemeToggle(!darkMode);
          }}
        >
          {darkMode ? <DarkModeIcon /> : <LightModeIcon />}
        </IconButton>
        {loggedIn ? (
          <AccountDropdown />
        ) : featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY) ? (
          <div>
            <Button
              variant="outlined"
              style={{ marginRight: '20px' }}
              onClick={() => openLoginModal('register')}
            >
              Register for Access
            </Button>
            <Button
              variant="contained"
              sx={{ whiteSpace: 'nowrap' }}
              onClick={() => openLoginModal('login')}
            >
              Log in
            </Button>
          </div>
        ) : (
          <Button
            variant="contained"
            sx={{ whiteSpace: 'nowrap' }}
            onClick={() => openLoginModal('login')}
            id={LOGIN_BUTTON_ID}
          >
            Log in
          </Button>
        )}
      </div>
    </WebBase>
  );
};

export default WebMenu;
