import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.default,
    width: 400,
    borderRadius: 8,
    boxShadow: 24,
    padding: '20px 25px 25px 25px',
    '@media (max-width: 450px)': {
      width: '60%',
    },
  },
  close: {
    position: 'absolute',
    right: '10px',
    top: '12px',
  },
  title: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontWeight: 500,
    paddingBottom: '10px',
  },
  note: {
    color: theme.palette.grey.main,
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '0.85rem',
  },
  mobileButton: {
    position: 'fixed',
    bottom: '0px',
    justifyContent: 'center',
    margin: '10px',
    borderRadius: '8px',
  },
  desktopButton: {
    margin: '0 8px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  mobileLogOutButton: {
    color: theme.palette.black.main,
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'none',
    paddingLeft: '0px',
  },
  desktopLogOutButton: {
    color: theme.palette.black.main,
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'none',
    padding: '8px 16px',
    width: '100%',
    justifyContent: 'space-between',
  },
  errorSnackbar: {
    backgroundColor: theme.palette.error,
    width: '100%',
  },
}));
