import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import useStyles from '../style';
import ReactGA from 'react-ga4';
import { ROUTE } from 'routes';
import { PAGES } from 'pages';

const PrivacyStatementText = () => {
  const classes = useStyles();
  ReactGA.send({
    hitType: 'pageview',
    page: ROUTE.PRIVACY,
    title: PAGES.PRIVACY,
  });
  return (
    <>
      <Typography className={classes.subtitle} variant="subtitle1">
        April 2023
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        Privacy Policy
      </Typography>
      <Typography className={classes.note} variant="h7">
        This Privacy Policy describes how Cybera Inc. (we) collect, use or
        otherwise process information about you as it relates to the SkillUp web
        application (the Site) as developed by Cybera Inc. a not-for -profit
        operating in Canada.
      </Typography>
      <Typography className={classes.note} variant="h7">
        This Privacy Policy only applies only if you visit{' '}
        <a
          className={classes.link}
          href="https://skill-up.ca"
          rel="external nofollow noopener noreferrer"
          target="_blank"
        >
          skill-up.ca
        </a>{' '}
        from within Canada.
      </Typography>
      <Typography className={classes.note} variant="h7">
        We may change this Privacy Policy from time to time. If we make changes,
        we will notify you by revising the date at the top of this policy. We
        encourage you to review this Privacy Policy regularly to stay informed
        about our information practices and the choices available to you.
      </Typography>
      <Typography className={classes.subtitle} variant="h6">
        Collection of Information
      </Typography>
      <Typography className={classes.note} variant="h7">
        The information we collect about you depends on how you interact with us
        or use the Site. In this section, we describe the categories of
        information we collect and the sources of this information.
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        Information You Provide to Us
      </Typography>
      <Typography className={classes.note} variant="h7">
        Cybera stores no personally identifiable information. Having
        authenticated with the Site, we may store information garnered or
        derived from your interaction to improve on your experience when using
        the site; for example, to remember your choices between sessions.
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        Information We Collect Automatically
      </Typography>
      <Typography className={classes.note} variant="h7">
        If you have authenticated with the Site we will store certain of your
        selections as you navigate the Site. This is to allow you to most
        effectively use the application between sessions.
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        Information We Collect from Other Sources
      </Typography>
      <Typography className={classes.note} variant="h7">
        The Site leverages Google for authentication. If you authenticate with
        Google you will be asked to share your name, email address, language
        preference and profile picture with SkillUp. The Site only records a
        globally unique identifier that Google creates and associates with your
        account. If your Google account is disabled or deleted your information
        as stored by the web site becomes inaccessible.
      </Typography>
      <Typography className={classes.subtitle} variant="h6">
        Use of Information
      </Typography>
      <Typography className={classes.note} variant="h7">
        The information we collect is used for the exclusive purpose of
        optimizing the user experience.
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        Data Retention
      </Typography>
      <Typography className={classes.note} variant="h7">
        Our retention period for information required to support you between
        sessions is based on business needs and legal requirements. We retain
        the right to purge information associated with your login six months
        after your last access.
      </Typography>
      <Typography className={classes.subtitle} variant="h6">
        Contact Us
      </Typography>
      <Typography className={classes.note} variant="h7">
        If you have any questions about this Privacy Policy, please{' '}
        <a
          className={classes.link}
          href="mailto:privacy@cybera.ca?subject=Skill-Up%20Privacy%20Policy"
          rel="external nofollow noopener noreferrer"
          target="_blank"
        >
          contact us
        </a>
        .
      </Typography>
    </>
  );
};

export const PrivacyStatement = ({ modal }) => {
  const classes = useStyles();

  return modal ? (
    <PrivacyStatementText />
  ) : (
    <Container className={classes.page}>
      <Typography className={classes.title} variant="h4">
        Privacy Statement
      </Typography>
      <PrivacyStatementText />
    </Container>
  );
};

export default PrivacyStatement;
