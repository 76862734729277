import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const PREFIX = 'BackButton';

export const classes = {
  button: `${PREFIX}-button`,
};

export const CustomButton = styled(Button)(({ theme }) => ({
  [`&.${classes.button}`]: {
    color: theme.palette.primary.main,
    margin: '8px 0px',
    padding: '2px 8px 2px 4px',
  },
}));
