import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { jwtDecode } from 'jwt-decode';
import { clearSession, SESSION_TYPE } from 'services/sessionHistoryService';
import { auth } from './firebaseApp';
import { env } from './env';

export function createTokenRefreshLink() {
  return new TokenRefreshLink({
    isTokenValidOrUndefined: async () => {
      const accessToken = JSON.parse(sessionStorage.getItem('isUserLoggedIn'));
      if (!accessToken) return true;
      const claims = jwtDecode(accessToken.token);
      const expiration = claims.exp * 1000;
      const valid = expiration >= new Date().getTime();
      return valid;
    },
    fetchAccessToken: async () => {
      const response = await fetch(
        `${env.REACT_APP_SERVER_REST_URL}/v1/users/refresh`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        }
      );
      const result = await response.json();
      return result;
    },
    handleFetch: accessToken => {
      if (accessToken === 'UNAUTHORIZED') {
        sessionStorage.removeItem('isUserLoggedIn');
        clearSession({ sessionType: SESSION_TYPE.PREFS });
        auth.signOut();
        window.location.reload();
      } else {
        const claims = JSON.parse(sessionStorage.getItem('isUserLoggedIn'));
        claims.token = accessToken;
        sessionStorage.setItem('isUserLoggedIn', JSON.stringify(claims));
      }
    },
    handleResponse: () => response => {
      if (response.token) {
        return { access_token: response.token };
      } else {
        return { access_token: 'UNAUTHORIZED' };
      }
    },
  });
}
