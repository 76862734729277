import React, { useState, useContext } from 'react';

import { Link } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { AuthContext } from '../../../../Auth';

import { LogoutSection } from 'components/sections/Logout/LogoutSection';

export const AvatarMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { currentUser } = useContext(AuthContext);

  const displayName =
    currentUser.firstname +
    ' ' +
    (currentUser.lastname ? currentUser.lastname : '');
  const photoURL = currentUser.photoUrl;
  const email = currentUser.email;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        {photoURL ? (
          <Avatar
            src={photoURL}
            referrerPolicy="no-referrer"
            sx={{ width: '35px', height: '35px' }}
          />
        ) : (
          <Avatar
            referrerPolicy="no-referrer"
            sx={{ width: '35px', height: '35px' }}
          >
            {displayName.charAt(0).toUpperCase()}
          </Avatar>
        )}
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={event => event.stopPropagation()}
          style={{ backgroundColor: 'transparent', cursor: 'auto' }}
        >
          {photoURL ? (
            <Avatar referrerPolicy="no-referrer" src={photoURL} />
          ) : (
            <Avatar referrerPolicy="no-referrer">
              {displayName.charAt(0).toUpperCase()}
            </Avatar>
          )}
          <div>
            <Typography>{displayName}</Typography>
            <Typography sx={{ opacity: 0.7, fontSize: '13px' }}>
              {email}
            </Typography>
          </div>
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to="/favourites">
          <ListItemIcon>
            <FavoriteBorderIcon fontSize="small" />
          </ListItemIcon>
          <Typography>My favourites</Typography>
        </MenuItem>
        <Divider />
        {currentUser.admin && (
          <MenuItem component={Link} to="/admin">
            <ListItemIcon>
              <AdminPanelSettingsOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <Typography>Admin settings</Typography>
            <Divider />
          </MenuItem>
        )}
        {currentUser.admin && <Divider />}
        <LogoutSection />
      </Menu>
    </>
  );
};
