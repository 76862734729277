import React, { useState, useEffect } from 'react';

import MobileMenu from './mobileMenu';
import WebMenu from './webMenu';
import { Breadcrumb } from 'components/ui';
import { useMediaQuery } from '@mui/material';

import { classes, AppBar } from './style';

const Header = ({ darkMode, handleThemeToggle }) => {
  const [imageIsReady, setImageIsReady] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(null);

  const logoSource = '/assets/skill-up-logo-horizontal.svg';
  const logoSourceMobile = '/assets/skill-up-logo-text-only.svg';

  const image = new Image();
  image.src = logoSource;
  image.onload = () => {
    setImageIsReady(true);
  };

  // check scroll direction to hide or show header
  useEffect(() => {
    let lastScrollY = window.scrollY;
    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      const direction = scrollY > lastScrollY ? 'down' : 'up';
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener('scroll', updateScrollDirection);
    return () => {
      window.removeEventListener('scroll', updateScrollDirection);
    };
  }, [scrollDirection]);

  const mobile = useMediaQuery(`(max-width: 1140px)`);

  useEffect(() => {
    const showHeader = () => {
      setScrollDirection('');
    };
    window.addEventListener('revealHeader', showHeader);

    return () => {
      window.removeEventListener('revealHeader', showHeader);
    };
  }, []);

  return imageIsReady ? (
    mobile ? (
      <>
        <AppBar className={classes.mobileHeader}>
          <MobileMenu
            logoSource={logoSource}
            logoSourceMobile={logoSourceMobile}
            scrollDirection={scrollDirection}
            darkMode={darkMode}
            handleThemeToggle={handleThemeToggle}
          />
        </AppBar>
        <Breadcrumb />
      </>
    ) : (
      <AppBar
        className={
          scrollDirection === 'down'
            ? classes.header + ' ' + classes.headerHide
            : classes.header + ' ' + classes.headerShow
        }
      >
        <WebMenu
          logoSource={logoSource}
          scrollDirection={scrollDirection}
          darkMode={darkMode}
          handleThemeToggle={handleThemeToggle}
        />
        <Breadcrumb />
      </AppBar>
    )
  ) : (
    <></>
  );
};

export default Header;
