import { styled } from '@mui/material';

const PREFIX = 'MobileHeader';

export const classes = {
  toolbar: `${PREFIX}-toolbar`,
  menuIcon: `${PREFIX}-menuIcon`,
  logo: `${PREFIX}-logo`,
  subMenu: `${PREFIX}-subMenu`,
};

export const MobileBase = styled('div')(({ theme }) => ({
  [`&.${classes.toolbar}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    minHeight: '50px',
    button: {
      color: theme.palette.primary.main,
    },
    backgroundColor: theme.palette.background.default,
  },
  [`& .${classes.menuIcon}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${classes.logo}`]: {
    padding: '0px 10px',
  },
  [`& .${classes.subMenu}`]: {
    color: theme.palette.grey.main,
    fontSize: '18px',
  },
}));
