import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { classes, MobileSearchGrid } from './style';

const CareerAreaSearchMobile = ({
  onChange,
  toggleSearchDrawer,
  careerArea,
  careerAreaOptions,
  app,
}) => {
  const [inputValue, setInputValue] = useState(careerArea);

  useEffect(() => {
    setInputValue(careerArea);
  }, [careerArea]);

  const formatResult = careerArea => {
    if (inputValue.length > 0) {
      const matches = match(careerArea, inputValue, {
        insideWords: true,
        requireMatchAll: true,
      });

      const parts = parse(careerArea, matches);

      return parts.map((part, index) => (
        <span
          key={index}
          style={{
            fontWeight: part.highlight ? 700 : 400,
            whiteSpace: 'pre',
          }}
        >
          {part.text}
        </span>
      ));
    }

    return careerArea;
  };

  const filteredCareerAreas =
    inputValue === 'All Sectors'
      ? careerAreaOptions
      : careerAreaOptions.filter(({ label }) =>
          label.toLowerCase().includes(inputValue.toLowerCase())
        );

  return (
    <MobileSearchGrid container>
      <Grid item xs={12} className={classes.header}>
        <Grid container className={classes.searchBar}>
          <Grid item xs={2} sm={1} className={classes.backButton}>
            <IconButton
              color={app}
              aria-label="Back"
              onClick={() => toggleSearchDrawer(false)}
              sx={{ width: '100%' }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10} sm={11}>
            <Autocomplete
              disablePortal
              forcePopupIcon={false}
              freeSolo
              open
              id="combo-box-demo"
              options={careerAreaOptions}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                // Only set input value when user types
                if (event !== null && event.type !== 'blur') {
                  setInputValue(newInputValue);
                }
              }}
              PaperComponent={props => (
                <Paper
                  sx={{
                    display: 'none',
                  }}
                  {...props}
                />
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  color={app}
                  inputRef={input => input && input.focus()}
                  placeholder="Search career areas"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      borderRadius: '12px',
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {filteredCareerAreas.map(value => (
          <Box
            key={value.label}
            className={classes.result}
            onClick={() => {
              onChange(value, 'selectOption');
              toggleSearchDrawer(false);
            }}
          >
            <SearchIcon className={classes.searchIcon} />
            {formatResult(value.label)}
          </Box>
        ))}
      </Grid>
    </MobileSearchGrid>
  );
};

export default CareerAreaSearchMobile;
