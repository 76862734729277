import React from 'react';
import { useNavigate } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { CustomButton, classes } from './style';

export const BackButton = ({ disabled, dest }) => {
  const navigate = useNavigate();

  // This handles the case where the page is opened directly (i.e. not going through the full process).
  // const notEnoughHistory = history.length <= historyThreshold;
  const notEnoughHistory = true;

  const goBack = () => {
    if (notEnoughHistory && dest) {
      navigate(dest);
    } else {
      navigate(-1);
    }
  };

  return (
    <CustomButton
      className={classes.button}
      startIcon={<ChevronLeftIcon />}
      disabled={disabled}
      color="white"
      variant="contained"
      size="small"
      onClick={goBack}
    >
      Back
    </CustomButton>
  );
};
