import React, { useState } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

import { DropdownSearchDrawer } from 'modules/mobileSearchDrawer';

import { classes, Item } from './style';
import {
  Box,
  Divider,
  Autocomplete,
  TextField,
  Paper,
  Drawer,
  useTheme,
  alpha,
} from '@mui/material';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const prepareOptions = options => {
  if (options.length === 0) return options;

  const firstOption = options[0];
  if (typeof firstOption === 'string') {
    return options.map(item => ({
      label: item,
      value: item,
    }));
  } else {
    if (
      Object.keys(firstOption).indexOf('label') > -1 &&
      Object.keys(firstOption).indexOf('value') > -1
    ) {
      return options;
    } else {
      throw new Error('Options must contain a label and value');
    }
  }
};
export const DropdownSelector = ({
  id,
  label,
  onChange,
  value,
  options = [],
  defaultOption = '',
  app = 'indemand',
  dropdownStyles,
  group = '',
}) => {
  const theme = useTheme();

  const [state, setState] = useState(value);
  if (value !== state) setState(value);

  const [searchDrawer, setSearchDrawer] = useState(false);

  const toggleSearchDrawer = open => {
    setSearchDrawer(open);
  };

  const onSelect = (event, value) => {
    let v = '';
    if (!value) {
      v = '';
    } else if (event.target?.value) {
      v = event.target.value;
    } else if (Object.keys(value)?.includes('value')) {
      v = value.value;
    } else {
      v = defaultOption;
    }
    setState(v);
    onChange(v);
  };

  const selectedValue = state === undefined ? '' : state;

  const dataProvider = prepareOptions(options);

  const isMediumScreen = useMediaQuery(
    `(max-width:${localStorage.getItem('MD_SCREEN')})`
  );

  const isMobile = useMediaQuery(
    `(max-width:${localStorage.getItem('SM_SCREEN')})`
  );

  return (
    <Item variant="outlined" className={classes.formControl}>
      <Autocomplete
        id={id}
        value={options.length > 0 ? selectedValue : ''}
        disableClearable={isMediumScreen}
        onChange={onSelect}
        disabled={options.length === 0}
        options={dataProvider}
        getOptionLabel={option => {
          return (
            option?.label ||
            dataProvider[dataProvider.findIndex(obj => obj.value === option)]
              ?.label ||
            option
          );
        }}
        isOptionEqualToValue={(option, selected) => option.value === selected}
        PaperComponent={props => (
          <Paper
            sx={{
              '& .MuiAutocomplete-listbox': {
                "& .MuiAutocomplete-option[aria-selected='true']": {
                  bgcolor: alpha(theme.palette[app].main, 0.12),
                  '&.Mui-focused': {
                    bgcolor: alpha(theme.palette[app].main, 0.12),
                  },
                },
              },
            }}
            {...props}
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          let matches = [];
          let parts = [];
          if (inputValue.length > 0) {
            matches = match(option.label, inputValue, {
              insideWords: true,
              requireMatchAll: true,
            });
            parts = parse(option.label, matches);
          }

          return (
            <React.Fragment key={option.label}>
              <Box component="li" {...props}>
                {inputValue.length > 0
                  ? parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                          whiteSpace: 'pre',
                        }}
                      >
                        {part.text}
                      </span>
                    ))
                  : option.label}
              </Box>
              {isMobile && <Divider />}
            </React.Fragment>
          );
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            color={app}
            InputProps={{
              ...params.InputProps,
              style: dropdownStyles,
            }}
            onClick={e => {
              if (isMediumScreen) {
                e.preventDefault();
                toggleSearchDrawer(true);
              }
            }}
          />
        )}
        groupBy={group ? option => option[group] : null}
      />
      <Drawer
        anchor="bottom"
        open={searchDrawer}
        onClose={() => toggleSearchDrawer(false)}
        onOpen={() => toggleSearchDrawer(true)}
        sx={{
          '.MuiDrawer-paper': {
            height: '100%',
            zIndex: 1500,
          },
        }}
      >
        <DropdownSearchDrawer
          toggleSearchDrawer={toggleSearchDrawer}
          options={options}
          onSelect={onSelect}
          selectedValue={selectedValue}
          dataProvider={dataProvider}
          defaultOption={defaultOption}
          label={label}
          id={id}
          app={app}
          dropdownStyles={dropdownStyles}
          group={group}
        />
      </Drawer>
    </Item>
  );
};
