import { styled } from '@mui/material';

const PREFIX = 'Header';

export const classes = {
  mobileHeader: `${PREFIX}-mobileHeader`,
  header: `${PREFIX}-header`,
  headerShow: `${PREFIX}-headerShow`,
  headerHide: `${PREFIX}-headerHide`,
  toolbar: `${PREFIX}-toolbar`,
  menuIcon: `${PREFIX}-menuIcon`,
  logo: `${PREFIX}-logo`,
  leftSection: `${PREFIX}-leftSection`,
  rightSection: `${PREFIX}-rightSection`,
  title: `${PREFIX}-title`,
  linkParent: `${PREFIX}-linkParent`,
  links: `${PREFIX}-links`,
};

export const AppBar = styled('div')(({ theme }) => ({
  [`&.${classes.mobileHeader}`]: {
    position: 'sticky',
    top: '0px',
    backgroundColor: theme.palette.background.default,
    boxShadow: '0 2px 6px 0 rgba(0,0,0,.12)',
    zIndex: 999,
    width: '100%',
  },
  [`&.${classes.header}`]: {
    position: 'fixed',
    backgroundColor: theme.palette.background.default,
    width: '100%',
    boxShadow: 'none',
    zIndex: 999,
    transition: 'all .5s ease',
  },
  [`&.${classes.headerShow}`]: {
    top: '0px',
    boxShadow: '0 2px 6px 0 rgba(0,0,0,.12)',
  },
  [`&.${classes.headerHide}`]: {
    top: '-125px',
  },
}));
