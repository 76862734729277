export default {
  JOB_TRANSITIONS: 'job_transitions',
  MARQUEE: 'marquee',
  INTERNAL_ONLY: 'internal_only',
  WORKING_IN_ALBERTA_FLAG: 'working_in_alberta',
  JOB_PREDICTIONS: 'job_predictions',
  MAINTENANCE: 'maintenance',
  PUBLIC_PREVIEW: 'public_preview',
  EDUCATIONAL_SUGGESTIONS: 'educational_suggestions',
  PSI_PROD: 'psi_production',
};
