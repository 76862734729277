import emailjs from '@emailjs/browser';
import {
  EXPIRED_ACCOUNT_TEMPLATE_ID,
  REJECTION_EMAIL_TEMPLATE_ID,
  APPROVAL_EMAIL_TEMPLATE_ID,
  RESET_PASSWORD_TEMPLATE_ID,
  USER_REGISTRATION_ALERT_TEMPLATE_ID,
  WELCOME_EMAIL_TEMPLATE_ID,
} from 'data/defaults/emailJsTemplates';
import { env } from '../env';
import { gql, useLazyQuery, useMutation } from '@apollo/client';

const VERIFY_TOKEN = gql`
  query VerifyResetToken($email: String!, $token: String!) {
    verifyResetToken(input: { email: $email, token: $token })
      @rest(
        path: "/users/validate-reset-token/"
        method: "POST"
        endpoint: "v1"
      ) {
      success
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!, $token: String!, $password: String!) {
    resetPassword(input: { email: $email, token: $token, password: $password })
      @rest(path: "/users/reset-password", method: "POST", endpoint: "v1") {
      success
    }
  }
`;

const GENERATE_RESET_TOKEN = gql`
  query GenerateResetToken($email: String!) {
    generateResetToken(input: { email: $email })
      @rest(
        path: "/users/generate-reset-token"
        method: "POST"
        endpoint: "v1"
      ) {
      success
      link
      expired
      username
    }
  }
`;

// env variables
const emailServiceId = env.REACT_APP_EMAILJS_SERVICE_ID;
const emailjsAuthKey = env.REACT_APP_EMAILJS_KEY;

const sendAccountExpiredEmail = async ({ email, username }) => {
  try {
    const emailParams = {
      username, // This will be filled with user's firstname
      email,
    };

    await emailjs.send(
      emailServiceId,
      EXPIRED_ACCOUNT_TEMPLATE_ID,
      emailParams,
      emailjsAuthKey
    );
  } catch (error) {
    console.log(error);
  }
};

export const sendApprovalEmail = async user => {
  try {
    const { firstname, email } = user;
    const emailParams = {
      name: firstname,
      email: email.toLowerCase(),
    };

    await emailjs.send(
      emailServiceId,
      APPROVAL_EMAIL_TEMPLATE_ID,
      emailParams,
      emailjsAuthKey
    );
  } catch (error) {
    console.log(error);
  }
};

export const sendRejectionEmail = async user => {
  try {
    const { firstname, email } = user;
    const emailParams = {
      name: firstname,
      email: email.toLowerCase(),
    };

    await emailjs.send(
      emailServiceId,
      REJECTION_EMAIL_TEMPLATE_ID,
      emailParams,
      emailjsAuthKey
    );
  } catch (error) {
    console.log(error);
  }
};

export const sendUserRegistrationAlert = async user => {
  try {
    const { firstname, lastname, email, institution } = user;
    const emailParams = {
      name: firstname + ' ' + lastname,
      user_email: email.toLowerCase(),
      institution: institution,
      email: env.REACT_APP_ADMIN_EMAIL,
    };

    await emailjs.send(
      emailServiceId,
      USER_REGISTRATION_ALERT_TEMPLATE_ID,
      emailParams,
      emailjsAuthKey
    );
  } catch (error) {
    console.log(error);
  }
};

export const usePasswordResetService = (email, token) => {
  const [validateResetToken, { loading: validateTokenLoading }] = useLazyQuery(
    VERIFY_TOKEN,
    {
      fetchPolicy: 'network-only',
    }
  );
  const [resetPassword] = useMutation(RESET_PASSWORD);
  const [generateResetToken] = useLazyQuery(GENERATE_RESET_TOKEN, {
    fetchPolicy: 'network-only',
  });

  const validateToken = async () => {
    const result = await validateResetToken({
      variables: {
        email,
        token,
      },
    });
    return result.data.verifyResetToken.success;
  };

  const resetUserPassword = async password => {
    const result = await resetPassword({
      variables: {
        email,
        token,
        password,
      },
    });
    return result.data.resetPassword.success;
  };

  const sendResetPasswordEmail = async (user, initial = false) => {
    try {
      const { firstname, email } = user;
      const response = await generateResetToken({
        variables: {
          email: email.toLowerCase(),
        },
      });
      const payload = response.data.generateResetToken;
      if (!payload.success) {
        if (payload.expired) {
          sendAccountExpiredEmail({
            email: email.toLowerCase(),
            username: payload.username,
          });
        }
        return {
          success: true,
          message: 'Reset email was not sent due to user status',
        };
      }
      // Use emailjs to send the email

      const emailParams = {
        name: firstname,
        reset_link: payload.link,
        email: email.toLowerCase(),
      };

      await emailjs.send(
        emailServiceId,
        initial ? WELCOME_EMAIL_TEMPLATE_ID : RESET_PASSWORD_TEMPLATE_ID,
        emailParams,
        emailjsAuthKey
      );

      return {
        success: true,
        message: 'Reset password email sent successfully',
      };
    } catch (error) {
      console.error(error);
      return { success: false, message: 'Error sending reset password email' };
    }
  };

  return {
    validateToken,
    validateTokenLoading,
    resetUserPassword,
    sendResetPasswordEmail,
  };
};
