import { styled } from '@mui/material';

const PREFIX = 'Login';

export const classes = {
  base: `${PREFIX}-base`,
  boxMain: `${PREFIX}-boxMain`,
  container: `${PREFIX}-container`,
  textContainer: `${PREFIX}-textContainer`,
  linkText: `${PREFIX}-linkText`,
  googleButton: `${PREFIX}-googleButton`,
  resetContainer: `${PREFIX}-resetContainer`,
  resetButton: `${PREFIX}-resetButton`,
};

export const Root = styled('div')(() => ({
  [`&.${classes.base}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 900px)': {
      margin: '30px 0px',
    },
  },
  [`& .${classes.boxMain}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '450px',
    '@media (max-width: 600px)': {
      width: '90%',
    },
  },
}));

export const Form = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${classes.textContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
  },
  [`& .${classes.linkText}`]: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    paddingBottom: '20px',
    '&:hover': {
      fontWeight: 450,
      textDecoration: 'underline',
    },
  },
  [`& .${classes.googleButton}`]: {
    color: theme.palette.text.main,
    margin: '20px 0px 10px',
  },
}));

export const ResetContainer = styled('div')(({ theme }) => ({
  [`&.${classes.resetContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    margin: '150px auto',
    width: '600px',
    '@media (max-width: 600px)': {
      width: '90%',
      marginTop: '50px',
    },
  },
  [`& .${classes.boxMain}`]: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.level1,
    width: '100%',
    '@media (min-width: 600px)': {
      padding: '20px 20px 40px',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '8px',
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
  },
  [`& .${classes.resetButton}`]: {
    marginTop: '20px',
  },
}));
