import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import {
  Grid,
  Typography,
  IconButton,
  Box,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Button,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';

import featureFlagManager, { FLAG } from 'modules/featureFlagManager';

import { LoginModalContext } from 'modules/loginModalContext/LoginModal';
import { AvatarMenu } from './avatarMenu';
import { menuItems } from '../content.js';
import { classes, MobileBase } from './style';
import { LOGIN_BUTTON_ID } from 'modules/signInPrompt/index.tsx';

const MobileMenu = ({
  logoSource,
  logoSourceMobile,
  darkMode,
  handleThemeToggle,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { openLoginModal } = useContext(LoginModalContext);

  const [loggedIn, setLoggedIn] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [open, setOpen] = useState({ open: {} });

  const toggleDrawer = open => {
    setDrawer(open);
    setOpen({ open: {} });
  };

  const handleOpen = title => () => {
    setOpen({ [title]: !open[title] });
  };

  const handleLogin = formType => {
    featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY)
      ? navigate('/' + formType)
      : openLoginModal(formType);
  };

  useEffect(() => {
    if (!loggedIn) {
      const user = JSON.parse(sessionStorage.getItem('isUserLoggedIn'));
      setLoggedIn(user ? true : false);
    }
  }, [location, loggedIn]);

  return (
    <MobileBase className={classes.toolbar}>
      <Grid container alignItems="center" className={classes.logo}>
        <Grid item xs={4}>
          {loggedIn ||
          !featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY) ? (
            <IconButton size="small" onClick={() => toggleDrawer(true)}>
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          ) : (
            <Button variant="outlined" onClick={() => handleLogin('register')}>
              Register
            </Button>
          )}
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Link to="/">
            <img
              src={logoSourceMobile}
              alt="SKILL-UP"
              style={{ height: '30px' }}
            ></img>
          </Link>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end' }}>
          {loggedIn ? (
            <AvatarMenu />
          ) : (
            <Button
              variant="contained"
              style={{ color: 'white' }}
              onClick={() => handleLogin('login')}
              id={LOGIN_BUTTON_ID}
            >
              Log in
            </Button>
          )}
        </Grid>
      </Grid>
      {(loggedIn || !featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY)) && (
        <Drawer
          anchor="left"
          open={drawer}
          onClose={() => toggleDrawer(false)}
          PaperProps={{
            sx: { width: '70%' },
          }}
        >
          <Box role="presentation">
            <Grid
              container
              justifyContent="space-between"
              sx={{ padding: '10px' }}
            >
              <img
                src={logoSource}
                alt="SKILL-UP"
                style={{ height: '50px' }}
              ></img>
              <IconButton
                style={{ width: '50px' }}
                onClick={() => {
                  handleThemeToggle(!darkMode);
                }}
              >
                {darkMode ? <DarkModeIcon /> : <LightModeIcon />}
              </IconButton>
            </Grid>
            <Divider />
            <List sx={{ paddingTop: '0px' }}>
              <ListItem disablePadding>
                <ListItemButton
                  component={Link}
                  to="/home"
                  onClick={() => toggleDrawer(false)}
                >
                  <ListItemIcon>
                    <HomeOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItemButton>
              </ListItem>
              <Divider />
              {menuItems.map(({ title, icon: Icon, link, subMenu }, index) => {
                return (
                  !(
                    !featureFlagManager.featureEnabled(
                      FLAG.WORKING_IN_ALBERTA_FLAG
                    ) && title === 'Working in Alberta'
                  ) && (
                    <React.Fragment key={index}>
                      <ListItem disablePadding>
                        {subMenu ? (
                          <ListItemButton onClick={handleOpen(title)}>
                            <ListItemIcon>
                              <Icon />
                            </ListItemIcon>
                            <ListItemText primary={title} />
                            {open[title] ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </ListItemButton>
                        ) : (
                          <ListItemButton
                            component={Link}
                            to={link}
                            onClick={() => toggleDrawer(false)}
                          >
                            <ListItemIcon>
                              <Icon />
                            </ListItemIcon>
                            <ListItemText primary={title} />
                          </ListItemButton>
                        )}
                      </ListItem>
                      {subMenu && (
                        <Collapse in={open[title]} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {subMenu.map((menu, index) => (
                              <React.Fragment key={index}>
                                <Divider />
                                <ListItem disablePadding>
                                  <ListItemButton
                                    component={Link}
                                    to={menu.link}
                                    onClick={() => toggleDrawer(false)}
                                  >
                                    <ListItemText
                                      disableTypography
                                      primary={
                                        <Typography
                                          variant="body2"
                                          sx={{ paddingLeft: '20px' }}
                                        >
                                          {menu.title}
                                        </Typography>
                                      }
                                    />
                                  </ListItemButton>
                                </ListItem>
                              </React.Fragment>
                            ))}
                          </List>
                        </Collapse>
                      )}
                      <Divider />
                    </React.Fragment>
                  )
                );
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </MobileBase>
  );
};

export default MobileMenu;
