import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

export const menuItems = [
  {
    title: 'Occupations',
    icon: WorkOutlineIcon,
    subMenu: [
      {
        title: 'In Demand',
        link: '/demand/search?area=All%20Sectors&region=All%20Regions',
      },
      {
        title: 'Fastest Growing',
        link: '/growth/search?area=All%20Sectors&region=All%20Regions',
      },
      {
        title: 'All Occupations',
        link: '/explore',
      },
    ],
  },
  {
    title: 'Skills Explorer',
    icon: ExploreOutlinedIcon,
    link: '/skills-explorer',
  },
  {
    title: 'Working in Alberta',
    icon: LocationOnOutlinedIcon,
    link: '/working-in-ab',
  },
  {
    title: 'Interests Quiz',
    icon: InterestsOutlinedIcon,
    link: '/interests-quiz/quiz',
  },
  {
    title: 'About',
    icon: InfoOutlinedIcon,
    link: '/about',
  },
];
