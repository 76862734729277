import React, { useState, useEffect } from 'react';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

let lastDelta = 0;
const getNotificationText = delta =>
  delta > 0
    ? `Found ${delta} new occupation${delta > 1 ? 's' : ''}.`
    : `Filtered ${Math.abs(delta)} occupation${
        delta > 1 ? 's' : ''
      } from results.`;

export const Toast = ({ variant, lastCount, newCount } = {}) => {
  const [open, setOpen] = useState(false);

  const delta = newCount - lastCount;
  const content = getNotificationText(delta);

  useEffect(() => {
    if (delta !== lastDelta) {
      setOpen(true);
      lastDelta = delta;
    }
  }, [open, delta]);

  if (lastCount === 0) return null;

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={() => setOpen(false)}
    >
      <MuiAlert onClose={() => setOpen(false)} severity={variant}>
        {content}
      </MuiAlert>
    </Snackbar>
  );
};
