import { Box, Drawer, styled } from '@mui/material';

const PREFIX = 'LoginModal';

export const classes = {
  image: `${PREFIX}-image`,
  cardMobile: `${PREFIX}-cardMobile`,
  close: `${PREFIX}-close`,
  closeMobile: `${PREFIX}-closeMobile`,
  text: `${PREFIX}-text`,
};

export const LoginBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.white.main,
  width: 900,
  borderRadius: 8,
  boxShadow: 24,
  '@media (max-width: 900px)': {
    width: 450,
  },
  '@media (max-width: 450px)': {
    width: '90%',
  },
  [`& .${classes.image}`]: {
    width: '450px',
    boxShadow: 'inset 0 0 0 99999px rgba(0, 116, 174, 0.5)',
  },
  [`& .${classes.close}`]: {
    position: 'absolute',
    right: '10px',
    top: '12px',
  },
  [`& .${classes.text}`]: {
    maxHeight: 600,
    overflowY: 'scroll',
  },
}));

export const MobileDrawer = styled(Drawer)(() => ({
  [`& .${classes.closeMobile}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 'fit-content',
    marginTop: '15px',
    marginRight: '15px',
  },
  [`& .${classes.cardMobile}`]: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflow: 'auto',
  },
}));
