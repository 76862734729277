import { styled } from '@mui/material';

import Modal from '@mui/material/Modal';
import Popover from '@mui/material/Popover';

const PREFIX = 'SignInPrompt';

export const classes = {
  promptActions: `${PREFIX}-promptActions`,
  promptBody: `${PREFIX}-promptBody`,
  promptButton: `${PREFIX}-promptButton`,
  promptContent: `${PREFIX}-promptContent`,
  promptContentNoChildren: `${PREFIX}-promptContentNoChildren`,
  promptDiscardButton: `${PREFIX}-promptDiscardButton`,
  promptHeader: `${PREFIX}-promptHeader`,
  modalBody: `${PREFIX}-modalBody`,
  modalTitle: `${PREFIX}-modalTitle`,
  modalNote: `${PREFIX}-modalNote`,
};

export const SignInPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    overflowX: 'unset',
    overflowY: 'unset',
    '&::before': {
      content: '""',
      position: 'absolute',
      marginRight: '2rem',
      border: 1,
      top: 0,
      right: 0,
      width: 25,
      height: 25,
      backgroundColor: theme.palette.background.level1,
      boxShadow: theme.shadows[1],
      transform: 'translate(-50%, -50%) rotate(-45deg)',
      clipPath:
        'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))',
    },
  },
  [`& .${classes.promptActions}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '@media (max-width: 400px)': {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  [`& .${classes.promptBody}`]: {
    alignContent: 'center',
    alignItems: 'center',
    background: theme.palette.background.level1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '10px',
    maxWidth: 552,
  },
  [`& .${classes.promptContent}`]: {
    '& .MuiTypography-root': {
      fontWeight: 400,
      fontSize: 16,
    },
  },
  [`& .${classes.promptContentNoChildren}`]: {
    textAlign: 'center',
  },
  [`& .${classes.promptButton}`]: {
    width: 150,
    marginBottom: '16px',
  },
  [`& .${classes.promptDiscardButton}`]: {
    width: 150,
    marginBottom: '16px',
  },
  [`& .${classes.promptHeader}`]: {
    width: '100%',
    paddingBottom: 0,
    alignContent: 'center',
    justifyContent: 'flex-end',
    marginBottom: '0px',
    '& .MuiSvgIcon-root': {
      fontSize: '1.1rem',
      color: theme.palette.black.main,
    },
  },
}));

export const SignInModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.modalBody}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.white.main,
    maxWidth: 300,
    maxHeight: 200,
    borderRadius: 8,
    boxShadow: 24,
    padding: '20px 25px 25px 25px',
  },
  [`& .${classes.modalClose}`]: {
    position: 'absolute',
    right: '10px',
    top: '12px',
  },
  [`& .${classes.modalTitle}`]: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontWeight: 500,
    paddingBottom: '10px',
  },
  [`& .${classes.modalNote}`]: {
    color: theme.palette.grey.main,
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '0.85rem',
  },
}));
