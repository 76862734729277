import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const PREFIX = 'FavouritesButton';

export const classes = {
  button: `${PREFIX}-button`,
};

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '12px',
    borderRadius: '12px',
    backgroundColor: '#F6F6F6',
    color: '#000000',
    border: '1px solid #D8D8D8',
  },
}));

export const ButtonRoot = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 600px)': {
    alignItems: 'flex-start',
  },
}));
